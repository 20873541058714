<template>
    <div>
        <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item to="/home">首页</el-breadcrumb-item>
            <el-breadcrumb-item>公告</el-breadcrumb-item>
        </el-breadcrumb>
        <el-card>
            <el-row :gutter="20">
                <el-col :span="5">
                    <el-input clearable placeholder="请输入内容" v-model="queryinfo.title">
                        <el-button slot="append" icon="el-icon-search" @click="reqNotice"></el-button>
                    </el-input>
                </el-col>
                <el-col :span="4">
                    <el-button type="primary" @click="addNotice">添加公告</el-button>
                </el-col>
            </el-row>
            <el-table :data="userlist" border stripe>
                <el-table-column label="公告ID" prop="notice_id"></el-table-column>
                <el-table-column label="标题" prop="title"></el-table-column>
                <el-table-column label="内容" prop="content"></el-table-column>
                <el-table-column label="操作">
                    <template slot-scope="scope">
                        <el-button type="primary" icon="el-icon-edit" size="mini"
                            @click="updateNotice(scope.row)"></el-button>
                        <el-button type="danger" icon="el-icon-delete" size="mini"
                            @click="deleteNotice(scope.row.notice_id)"></el-button>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                :current-page="queryinfo.page" :page-sizes="[5, 10, 15, 20]" :page-size="queryinfo.list_rows"
                layout="total, sizes, prev, pager, next, jumper" :total="total">
            </el-pagination>
        </el-card>
        <el-dialog :title="addForm.notice_id ? '修改公告' : '添加公告'" :visible.sync="dialogFormVisible" width="650px">
            <el-form v-model="addForm" label-width="100px" style="width: 80%;">
                <el-form-item label="标题：" prop="title">
                    <el-input v-model="addForm.title" />
                </el-form-item>
                <el-form-item label="内容：" prop="content">
                    <el-input v-model="addForm.content" />
                </el-form-item>

            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="cancel">取 消</el-button>
                <el-button type="primary" @click="confirm">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
export default {
    data() {
        return {
            queryinfo: {
                title: '',
                page: 1,
                list_rows: 5,
            },
            userlist: [],
            dialogFormVisible: false,
            addForm: {
                title: "",
                content: ""
            },
            total:0
        }
    },
    methods: {
        async reqNotice() {
            const { data: res } = await this.$http.post('notice/index', this.queryinfo)
            this.userlist = res.data.data
            this.total = res.data.total
        },
        addOrUpdateNotice(data) {
            if (data.notice_id) {
                return this.$http.post('notice/update', data)
            } else {
                return this.$http.post('notice/add', data)
            }
        },
        addNotice() {
            this.addForm.title = ""
            this.addForm.content = "";
            this.addForm.notice_id = ""
            this.dialogFormVisible = true
        },
        cancel() {
            this.dialogFormVisible = false
        },
        async confirm() {
            const { data: res } = await this.addOrUpdateNotice(this.addForm)
            if (res.code == 0) {
                this.$message.success(this.addForm.notice_id ? '修改成功' : '添加成功')
                this.reqNotice()
            } else {
                this.$message.error(this.addForm.notice_id ? '修改失败' : '添加失败')
            }
            this.dialogFormVisible = false
        },

        async deleteNotice(id) {
            const confirmResult = await this.$confirm(
                "此操作将永久删除该分类, 是否继续?",
                "提示",
                {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning",
                }
            ).catch((err) => err);
            // console.log(row);
            if (confirmResult !== "confirm")
                return this.$message.info("已经取消删除！");
            const { data: res } = await this.$http.post('notice/delete', { notice_id: id })
            if (res.code == 0) {
                this.$message.success('删除成功')
                this.reqNotice()
            } else {
                this.$message.error('删除失败')
            }
        },
        updateNotice(row) {
            Object.assign(this.addForm, row)
            this.dialogFormVisible = true
        },
        handleSizeChange(newsize) {
            this.queryinfo.list_rows = newsize
            this.reqNotice()
        },
        handleCurrentChange(newpage) {
            this.queryinfo.page = newpage
            this.reqNotice()
        }
    },
    mounted() {
        this.reqNotice()
    }
}
</script>

<style lang="scss" scoped></style>