<template>
  <div class="login_contaner">
    <div class="login_box">
        <div class="avator_box">
            <img src="../assets/logo.png">
        </div>
        <el-form :model="loginFrom" :rules="rules" ref="loginFormRef" label-width="0px" class="login_form">
            <el-form-item class="boderss" prop="account">
                <el-input v-model="loginFrom.account" prefix-icon="iconfont icon-users"></el-input>
            </el-form-item>
            <el-form-item class="boderss" prop="password">
                <el-input v-model="loginFrom.password"  prefix-icon="iconfont icon-3702mima"></el-input>
            </el-form-item>
            <el-form-item class="login_btns">
                  <el-button type="primary" @click="Login">提交</el-button>
                  <el-button type="info" @click="resetForm">重置</el-button>
            </el-form-item>
        </el-form>
    </div>
  </div>
</template>
<script>
    export default {
        data() {
            return {
                loginFrom: {
                    account: '',
                    password: ''
                },
                rules: {
                    account: [{
                        required: true,
                        message: '请输入用户名',
                        trigger: 'blur'
                    }, {
                        min: 3,
                        max: 16,
                        message: '长度在 3 到 16 个字符',
                        trigger: 'blur'
                    }],
                    password: [{
                        required: true,
                        message: '请输入密码',
                        trigger: 'blur'
                    }, {
                        min: 6,
                        max: 18,
                        message: '长度在 6 到 18 个字符',
                        trigger: 'blur'
                    }]
                }
            }
        },
        methods: {
            resetForm() {
                // this.loginFrom.account='zs',
                // this.loginFrom.password='123456'
                this.$refs['loginFormRef'].resetFields();
            },
            Login() {
                this.$refs.loginFormRef.validate(async valid => {
                    if (!valid) return
                        // console.log('正在提交');
                    const {
                        data: res
                    } = await this.$http.post('login/account', this.loginFrom)
                        // console.log(resualts);
                    if (res.code === 0) {
                        this.$message({
                            showClose: true,
                            message: res.message,
                            type: 'success'
                        });
                        // 跳转
                        window.sessionStorage.setItem('token', 'Bearer '+res.data.token)
                        this.$router.push('/home')
                    } else {
                        this.$message({
                            showClose: true,
                            message: res.message,
                            type: 'error'
                        });
                    }
                })
            }
        },
    }
</script>
<style lang="less" scoped>
    /*element ui 组件*/
    /*scoprd:阻止本组件样式扩散到本组件范围内生效*/
    
    .login_contaner {
        background-color: #9f883b;
        background-image: linear-gradient(rgba(206, 172, 58, 0.797) ,white);
        height: 100%;
    }
    
    .login_box {
        border: 1px solid rgb(183, 147, 39);
        width: 450px;
        height: 300px;
        background-color: rgb(242, 247, 244);
        border-radius: 10px;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
    }
    
    .avator_box {
        width: 130px;
        height: 130px;
        background-color: rgb(238, 244, 242);
        border: 1px solid rgb(162, 145, 30);
        border-radius: 50%;
        position: absolute;
        left: 50%;
        transform: translate(-50%, -50%);
        padding: 10px;
        box-shadow: 0 0 10px rgb(199, 194, 175);
    }
    
    img {
        width: 100%;
        height: 100%;
        border-radius: 50%;
        background-color: #eee;
    }
    
    .login_form {
        position: absolute;
        bottom: 0;
        width: 100%;
        padding: 20px;
        box-sizing: border-box;
        
    }
    .boderss{
        border: 1.5px solid rgb(180, 176, 94);
        border-radius: 10px;
    }    
    .login_btns {
        display: flex;
        justify-content: flex-end;
    }
</style>