<template>
  <div>
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item>素材</el-breadcrumb-item>
      <el-breadcrumb-item>素材列表</el-breadcrumb-item>
    </el-breadcrumb>
    <el-card>
      <el-row :gutter="20">
        <el-col :span="7">
          <el-input clearable placeholder=" 请输入内容" v-model="queryinfo.order_no">
            <el-button slot="append" icon="el-icon-search" @click="searchChange()"></el-button>
          </el-input>
        </el-col>

        <el-col :span="5">

          <el-select v-model="queryinfo.check_status" placeholder="请选择">
            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </el-col>
      </el-row>
      <el-table :data="userlist" border stripe>
        <!-- <el-table-column label="素材ID" prop="id"></el-table-column> -->
        <!-- <el-table-column label="审核员ID" prop="checker_id"></el-table-column> -->
        <!-- <el-table-column label="用户ID" prop="author_id"></el-table-column> -->
        <el-table-column label="素材ID" prop="material_id"></el-table-column>
        <el-table-column label="素材名称" prop="material_name"></el-table-column>
        <el-table-column label="演示图片" prop="demo_image_url">
          <template slot-scope="scope">
            <el-image :src="scope.row.demo_image_url" fit="fill" style="width: 60px;"
              :preview-src-list="[scope.row.demo_image_url]"></el-image>
          </template>
        </el-table-column>
        <el-table-column label="销售图币" prop="sale_currency"></el-table-column>
        <el-table-column label="分成类型" prop="distributed_type_text"></el-table-column>
        <el-table-column label="软件类型" prop="software_type_text"></el-table-column>
        <el-table-column label="审核状态" prop="check_status_text"></el-table-column>
        <el-table-column label="上架时间" prop="create_time"></el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-button type="primary" icon="el-icon-edit" size="mini"
              @click="showEditDialog(scope.row.material_id)"></el-button>
            <el-button type="primary" icon="el-icon-delete" size="mini"
              @click="deleteEditDialog(scope.row.material_id)"></el-button>
          </template>
        </el-table-column>
      </el-table>
      <!--分页区域-->
      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="queryinfo.page"
        :page-sizes="[5, 10, 15, 20]" :page-size="queryinfo.list_rows" layout="total, sizes, prev, pager, next, jumper"
        :total="total">
      </el-pagination>
    </el-card>
    <!-- 修改文章信息 -->
    <el-dialog title="查看素材信息" :visible.sync="editDialogVisible" width="500px" @closed="editDialogClosed">
      <el-form :model="editForm" :rules="editRules" ref="editFormRef" label-width="80px">
        <el-form-item label="审核状态">
          <el-radio v-model="editForm.value" label="1" border>通过</el-radio>
          <el-radio v-model="editForm.value" label="2" border>驳回</el-radio>
        </el-form-item>
        <el-form-item label="审核理由" prop="check_reason">
          <el-input v-model="editForm.check_reason"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="editDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="editRoles">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      // 获取文章信息列表
      queryinfo: {
        order_no: "",
        page: 1,
        list_rows: 5,
        check_status: ""
      },
      userlist: [],
      total: 0,
      //控制创建文章对话框展示与隐藏
      addDialogVisible: false,
      addForm: {
        title: "",
        editor: "",
        body: "",
        // 上传的图片临时路径
        pics: [],
      },
      addRules: {
        title: [
          {
            required: true,
            message: "请输入文章标题",
            trigger: "blur",
          },
        ],
        editor: [
          {
            required: true,
            message: "请输入作者名称",
            trigger: "blur",
          },
        ],
        body: [
          {
            required: true,
            message: "请输入文本内容",
            trigger: "blur",
          },
        ],
      },
      editDialogVisible: false,
      options: [
        {
          value: "",
          label: "全部"
        },
        {
          value: "1",
          label: "待审核"
        },
        {
          value: "2",
          label: "审核通过",
        },
        {
          value: "3",
          label: "驳回",
        },
      ],
      editForm: {
        value: '1',
        check_reason: ""
      },
      editRules: {
        title: [
          {
            required: true,
            message: "请输入文章标题",
            trigger: "blur",
          },
        ],
        editor: [
          {
            required: true,
            message: "请输入作者名称",
            trigger: "blur",
          },
        ],
        source: [
          {
            required: true,
            message: "请输入文章来源",
            trigger: "blur",
          },
        ],
        body: [
          {
            required: true,
            message: "请输入文本内容",
            trigger: "blur",
          },
        ],
      },
      // 上传图片的url
      uploadUrl: "http://127.0.0.1:8887/api/upload",
      // 图片上传组件的headers请求头对象
      headerObj: {
        Authorization: window.sessionStorage.getItem("token"),
      },
      previewPath: "",
      previewVisible: false,

    };
  },
  created() {
    this.getinforList();
  },
  methods: {
    // 获取所有菜单
    async getinforList() {
      const { data: res } = await this.$http.post("material/index", {
        // params: this.queryinfo,
        material_name: this.queryinfo.order_no,
        page: this.queryinfo.page,
        list_rows: this.queryinfo.list_rows,
        check_status: this.queryinfo.check_status
      });

      if (res.code !== 0) return this.$message.err("获取素材列表失败!");
      this.userlist = res.data.data;
      this.total = res.data.total;
    },
    // 监听handleSizeChange方法
    handleSizeChange(newsize) {
      this.queryinfo.list_rows = newsize;
      this.getinforList();
    },
    // 监听handleCurrentChange方法
    handleCurrentChange(newpage) {
      this.queryinfo.page = newpage;
      this.getinforList();
    },
    // 搜索方法
    searchChange() {
      this.queryinfo.page = 1;
      this.getinforList();
    },
    // 添加用户关闭
    addDialogClosed() {
      this.$refs["addFormRef"].resetFields();
    },
    //删除用户
    async deleteEditDialog(id) {
      // console.log(id);
      const confirmRessult = await this.$confirm(
        "此操作将永久删除, 是否继续?",
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      ).catch((err) => err);

      if (confirmRessult !== "confirm") {
        return this.$message.info("已经取消删除!");
      }
      const { data: res } = await this.$http.post("material/delete", {
        material_id: id,
      });
      if (res.code !== 0) {
        return this.$message.error("删除失败!");
      }
      console.log(res);
      this.$message.success("删除成功!");
      this.getinforList();
    },
    // 点击查看订单信息
    async showEditDialog(id) {
      this.editForm.check_reason = ""
      this.editForm.value = "1"
      this.editDialogVisible = true;
      this.editForm.material_id = id



    },
    editDialogClosed() {
      this.$refs["editFormRef"].resetFields();
    },
    editUser() {
      // 预验证
      this.$refs.editFormRef.validate(async (valid) => {
        if (!valid) return;
        // 调用后台数据创建用户
        const { data: res } = await this.$http.post(
          "updateWikis/" + this.editForm.id,
          this.editForm
        );
        if (res.code !== 0) return this.$message.error(res.meta.msg);
        this.$message.success("编辑成功");
        //    关闭添加窗口
        this.editDialogVisible = false;
        this.getinforList();
      });
    },
    // 审核确认按钮
    editRoles() {
      this.$refs.editFormRef.validate(async (valid) => {
        if (!valid) return;
        if (this.editForm.value == '1') {
          const { data: res } = await this.$http.post(`material/checkPass`, {
            material_id: this.editForm.material_id,
            check_reason: this.editForm.check_reason,
          });
          if (res.code !== 0) return this.$message.error("修改审核状态失败!");
          this.$message.success("修改审核状态成功!");
          this.getinforList();
          this.editDialogVisible = false;
        } else {
          const { data: res } = await this.$http.post(`material/checkRejected`, {
            material_id: this.editForm.material_id,
            check_reason: this.editForm.check_reason,
          });
          if (res.code !== 0) return this.$message.error("修改审核状态失败!");
          this.$message.success("修改审核状态成功!");
          this.getinforList();
          this.editDialogVisible = false;
        }

      });
    },
  },
};
</script>

<style lang="less" scoped></style>