<template>
    <div>
        <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item>用户管理</el-breadcrumb-item>
            <el-breadcrumb-item>用户职业</el-breadcrumb-item>
            <!-- <el-breadcrumb-item></el-breadcrumb-item> -->
        </el-breadcrumb>

        <el-card>
            <el-row :gutter="20">

                <el-col :span="4">
                    <el-button type="primary" @click="AddOccupation">添加职业</el-button>
                </el-col>
            </el-row>
            <el-table :data="userlist" border stripe>
                <el-table-column label="ID" prop="occupation_id"></el-table-column>
                <el-table-column label="职业名称" prop="occupation_name"></el-table-column>
                <el-table-column label="创建时间" prop="create_time"></el-table-column>
                <el-table-column label="操作">
                    <template slot-scope="scope">
                        <el-button type="primary" icon="el-icon-edit" size="mini"
                            @click="updateOccupation(scope.row)"></el-button>
                        <el-button type="danger" icon="el-icon-delete" size="mini" @click="deleteOccupation(scope.row)"></el-button>
                    </template>
                </el-table-column>
            </el-table>
        </el-card>
        <el-dialog :title="this.careerInformation.occupation_id ? '修改职业' : '添加职业'" :visible.sync="dialogFormVisible" width="750px">
            <el-form style="width: 80%;" label-position="right" label-width="100px">
                <el-form-item label="职业名称：">
                    <el-input placeholader="请输入职业名称" v-model="careerInformation.occupation_name"></el-input>
                </el-form-item>
                <el-form-item label="排序：">
                    <el-input-number v-model="careerInformation.sort" controls-position="right" :min="1" :max="100"></el-input-number>
                </el-form-item>
            </el-form>
            <template #footer>
                <el-button type="primary" size="default" @click="cancel">取消</el-button>
                <el-button type="primary" size="default" @click="confirm">确定</el-button>
            </template>
        </el-dialog>
    </div>
</template>
<script>
export default {
    data() {
        return {
            userlist: [],
            dialogFormVisible: false,
            careerInformation: {
                occupation_name: "",
                sort: ""
            }
        }
    },
    methods: {
        async reqAllCareer() {
            const { data: res } = await this.$http.post('occupation/all', { parent_id: 0 })
            console.log(res)
            this.userlist = res.data
        },
        cancel() {
            this.dialogFormVisible = false
        },
        confirm() {
            this.reqAddOrUpdateCareer()

        },
        async reqAddOrUpdateCareer() {
            if (this.careerInformation.occupation_id) {
                const { data: res } = await this.$http.post('occupation/update', this.careerInformation)
                if (res.code == 0) {
                    //关闭对话框
                    this.dialogFormVisible = false
                    //提交成功提示
                    this.$message.success('修改职业成功')
                    //重新请求数据
                    this.reqAllCareer()
                } else {
                    this.$message.error('修改职业失败')
                    //关闭对话框
                    this.dialogFormVisible = false
                }
            } else {
                const { data: res } = await this.$http.post('occupation/add', this.careerInformation)
                if (res.code == 0) {
                    //关闭对话框
                    this.dialogFormVisible = false
                    //提交成功提示
                    this.$message.success('添加职业成功')
                    //重新请求数据
                    this.reqAllCareer()
                } else {
                    this.$message.error('添加职业失败')
                    //关闭对话框
                    this.dialogFormVisible = false
                }
            }

        },
        AddOccupation() {
            //对话框显示
            this.careerInformation.occupation_name = ""
            this.careerInformation.sort = ""
            this.careerInformation.occupation_id = ""
            this.dialogFormVisible = true
        },
        updateOccupation(row) {
            this.dialogFormVisible = true
            console.log(row)
            this.careerInformation.occupation_name = row.occupation_name
            this.careerInformation.sort = row.sort
            this.careerInformation.occupation_id = row.occupation_id
        },
        async deleteOccupation(row) {
            const confirmRessult = await this.$confirm('此操作将永久删除, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).catch(err => err)

            if (confirmRessult !== 'confirm') {
                return this.$message.info('已经取消删除!')
            }
            const {
                data: res
            } = await this.$http.post(`occupation/delete`,{occupation_id:row.occupation_id})
            console.log(res)
            if (res.code == 0) {
                this.$message.success('删除成功！')
                this.reqAllCareer()
            } else {
                this.$message.error('删除失败！')
            }
            // if (res.meta.status !== 200) {
            //     return this.$message.error('删除失败!')
            // }
            // console.log(res);
            // this.$message.success('删除成功!')
            // this.getinforList()
        },
    },
    mounted() {
        this.reqAllCareer()
    },

}
</script>