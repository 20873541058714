<template>
    <div>
        <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item>素材</el-breadcrumb-item>
            <el-breadcrumb-item>素材分类</el-breadcrumb-item>
        </el-breadcrumb>
        <el-card>
            <el-row :gutter="20">
                <el-col :span="4">
                    <el-button type="primary" @click="addMaterialOneClassify">添加一级菜单</el-button>
                </el-col>
            </el-row>
            <el-tree :data="materialList" :props="defaultProps" node-key="material_category_id" style="margin-top: 25px;">
                <div class="custom-tree-node" slot-scope="{ node, data }">
                    <span style="width: 100px; display: inline-block; margin-left: 10px;">{{ data.category_name }}</span>
                    <span style="width: 100px; display: inline-block;">
                        <span style="width: 100px; display: inline-block;">
                            <el-tag v-if="node.level === 1">一级</el-tag>
                            <el-tag type="success" v-else>二级</el-tag>
                        </span>
                        <el-button type="primary" icon="el-icon-edit" size="mini"
                            @click.stop="showEditDialog(data)"
                            style="width: 100px; display: inline-block;">
                            编辑
                        </el-button>

                        <el-button type="primary" icon="el-icon-edit" v-if="node.level === 1" size="mini"
                            @click.stop="showEditDialogs(data.material_category_id)">添加二级菜单</el-button>
                        <el-button type="danger" icon="el-icon-delete" size="mini" style="margin-left: 50px;"
                            @click.stop="deleteEditDialog(data.material_category_id)">
                            删除
                        </el-button>
                    </span>
                </div>
            </el-tree>
        </el-card>
        <el-dialog title="添加一级素材分类" :visible.sync="centerDialogVisible" width="600px">
            <el-form ref="addForm" label-width="100px">
                <el-form-item label="分类名称：">
                    <el-input v-model="addForm.category_name" />
                </el-form-item>
                <el-form-item label="排序：">
                    <el-input v-model="addForm.sort" />
                </el-form-item>
            </el-form>
            <template #footer>
                <el-button type="primary" size="default" @click="cancel">取消</el-button>
                <el-button type="primary" size="default" @click="confirm">确定</el-button>
            </template>
        </el-dialog>
    </div>
</template>

<script>
export default {
    data() {
        return {
            materialList: [],
            defaultProps: {
                children: 'children',
                label: 'category_name'
            },
            centerDialogVisible: false,
            addForm: {
                parent_id: 0,
                category_name: "",
                sort: ""
            }
        }
    },
    methods: {
        async reqMaterialList() {
            const { data: res } = await this.$http.post('materialCategory/family')
            this.materialList = res.data
        },
        async addMaterialOneClassify() {
            this.addForm.parent_id = 0
            this.addForm.category_name = ""
            this.addForm.sort = ""
            this.addForm.material_category_id = ""
            this.centerDialogVisible = true
        },
        async confirm() {
            const { data: res } = await this.reqAddOrUpdateMaterial(this.addForm)
            if (res.code == 0) {
                console.log(res)

                this.$message.success("添加成功")
                this.reqMaterialList()
            }
            this.centerDialogVisible = false
        },
        cancel() {

            this.centerDialogVisible = false
        },
        reqAddOrUpdateMaterial(data) {
            if (!data.material_category_id) {
                return this.$http.post('materialCategory/add', data)
            } else {
                return this.$http.post('materialCategory/update', data)
            }
        },
        async deleteEditDialog(id) {
            const confirmRessult = await this.$confirm('此操作将永久删除, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).catch(err => err)

            if (confirmRessult !== 'confirm') {
                return this.$message.info('已经取消删除!')
            }
            const { data: res } = await this.$http.post('materialCategory/delete', { material_category_id: id })
            if (res.code == 0) {
                this.$message.success("删除成功")
                this.reqMaterialList()
            } else {
                this.$message.error("删除失败")
            }
        },
        showEditDialogs(id) {
            this.centerDialogVisible = true
            this.addForm.parent_id = id
            this.addForm.category_name = ""
            this.addForm.sort = ""
            this.addForm.material_category_id = ""
        },
        showEditDialog(data) {
            this.centerDialogVisible = true
            console.log(data)
            Object.assign(this.addForm,data)
        }
    },
    mounted() {
        this.reqMaterialList()
    }
}
</script>

<style lang="less" scoped>
::v-deep .el-tree-node__content {
    padding: 15px 0;
}
</style>