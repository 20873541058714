<template>
  <div>
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>素材会员的订单列表</el-breadcrumb-item>
      <el-breadcrumb-item> </el-breadcrumb-item>
    </el-breadcrumb>
    <el-card>
      <el-row :gutter="20">
        <el-col :span="7">
          <el-input clearable placeholder=" 请输入内容" v-model="queryinfo.order_no">
            <el-button slot="append" icon="el-icon-search" @click="searchChange()"></el-button>
          </el-input>
        </el-col>
        <el-col :span="3.5">
          <el-select v-model="queryinfo.pay_type" placeholder="请选择" @change="getinforList">
            <el-option v-for="item in paymentType" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </el-col>
        <el-col :span="3.5">
          <el-select v-model="queryinfo.order_status" placeholder="请选择" @change="getinforList">
            <el-option v-for="item in paymentStatus" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </el-col>
      </el-row>
      <el-table :data="userlist" border stripe>
        <el-table-column label="素材会员订单ID" prop="material_vip_order_id"></el-table-column>
        <el-table-column label="用户ID" prop="orderer_id"></el-table-column>
        <el-table-column label="订单号" prop="order_no"></el-table-column>
        <el-table-column label="订单总金额" prop="total_yuan"></el-table-column>
        <el-table-column label="提供的下载数量" prop="apply_download_amount"></el-table-column>
        <el-table-column label="剩余的下载数量" prop="surplus_download_amount"></el-table-column>
        <el-table-column label="支付方式" prop="pay_type_text"></el-table-column>
        <el-table-column label="订单状态" prop="order_status_text"></el-table-column>
        <el-table-column label="订单创建时间" prop="create_time"></el-table-column>
      </el-table>
      <!--分页区域-->
      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="queryinfo.page"
        :page-sizes="[5, 10, 15, 20]" :page-size="queryinfo.list_rows" layout="total, sizes, prev, pager, next, jumper"
        :total="total">
      </el-pagination>
    </el-card>
    <!-- 修改文章信息 -->
  </div>
</template>

<script>
export default {
  data() {
    return {
      // 获取文章信息列表
      queryinfo: {
        order_no: "",
        page: 1,
        list_rows: 5,
        pay_type: "",
        order_status: ""
      },
      userlist: [],
      total: 0,
      //控制创建文章对话框展示与隐藏
      addDialogVisible: false,
      addForm: {
        title: "",
        editor: "",
        body: "",
        // 上传的图片临时路径
        pics: [],
      },
      addRules: {
        title: [
          {
            required: true,
            message: "请输入文章标题",
            trigger: "blur",
          },
        ],
        editor: [
          {
            required: true,
            message: "请输入作者名称",
            trigger: "blur",
          },
        ],
        body: [
          {
            required: true,
            message: "请输入文本内容",
            trigger: "blur",
          },
        ],
      },
      editDialogVisible: false,
      editForm: {},
      editRules: {
        title: [
          {
            required: true,
            message: "请输入文章标题",
            trigger: "blur",
          },
        ],
        editor: [
          {
            required: true,
            message: "请输入作者名称",
            trigger: "blur",
          },
        ],
        source: [
          {
            required: true,
            message: "请输入文章来源",
            trigger: "blur",
          },
        ],
        body: [
          {
            required: true,
            message: "请输入文本内容",
            trigger: "blur",
          },
        ],
      },
      // 上传图片的url
      uploadUrl: "http://127.0.0.1:8887/api/upload",
      // 图片上传组件的headers请求头对象
      headerObj: {
        Authorization: window.sessionStorage.getItem("token"),
      },
      previewPath: "",
      previewVisible: false,
      paymentType: [
        {
          value: "",
          label: '全部'
        },
        {
          value: 1,
          label: '微信'
        },
        {
          value: 2,
          label: '支付宝'
        }
      ],
      paymentStatus:[
        {
          value: "",
          label: '全部'
        },
        {
          value: 1,
          label: '等待支付'
        },
        {
          value: 2,
          label: '支付成功'
        },
        {
          value: 3,
          label: '取消支付'
        },
        {
          value: 4,
          label: '支付失败'
        },
        {
          value: 5,
          label: '转入退款'
        }
      ]
    };
  },
  created() {
    this.getinforList();
  },
  methods: {
    // 获取所有菜单
    async getinforList() {
      const { data: res } = await this.$http.post("materialVipOrder/index", this.queryinfo);

      if (res.code !== 0) return this.$message.err("获取素材会员订单列表失败!");
      this.userlist = res.data.data;
      this.total = res.data.total;
    },
    // 监听handleSizeChange方法
    handleSizeChange(newsize) {
      this.queryinfo.list_rows = newsize;
      this.getinforList();
    },
    // 监听handleCurrentChange方法
    handleCurrentChange(newpage) {
      this.queryinfo.page = newpage;
      this.getinforList();
    },
    // 搜索方法
    searchChange() {
      this.queryinfo.page = 1;
      this.getinforList();
    },
    // 添加用户关闭
    addDialogClosed() {
      this.$refs["addFormRef"].resetFields();
    },
    // 点击查看订单信息
    editDialogClosed() {
      this.$refs["editFormRef"].resetFields();
    },
    editUser() {
      // 预验证
      this.$refs.editFormRef.validate(async (valid) => {
        if (!valid) return;
        // 调用后台数据创建用户
        const { data: res } = await this.$http.post(
          "updateWikis/" + this.editForm.id,
          this.editForm
        );
        if (res.code !== 0) return this.$message.error(res.meta.msg);
        this.$message.success("编辑成功");
        //    关闭添加窗口
        this.editDialogVisible = false;
        this.getinforList();
      });
    },
  },
};
</script>

<style lang="less" scoped></style>