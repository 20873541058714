<template>
    <div>
        <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item>用户管理</el-breadcrumb-item>
            <el-breadcrumb-item>管理员</el-breadcrumb-item>
            <!-- <el-breadcrumb-item></el-breadcrumb-item> -->
        </el-breadcrumb>
        <el-card>
            <el-row :gutter="20">
                <el-col :span="7">
                    <el-input clearable placeholder=" 请输入内容" v-model="queryinfo.query">
                        <el-button slot="append" icon="el-icon-search" @click="getinforList"></el-button>
                    </el-input>
                </el-col>
                <el-col :span="4">
                    <el-button type="primary" @click="addDialogVisible = true">添加管理员</el-button>
                </el-col>
            </el-row>
            <el-table :data="userlist" border stripe>

                <el-table-column label="管理员id" prop="admin_id" width="100"></el-table-column>
                <el-table-column label="创建者id" prop="creator_id" width="100"></el-table-column>
                <el-table-column label="编辑者id" prop="editor_id" width="100"></el-table-column>
                <el-table-column label="管理员账号" prop="account"></el-table-column>
                <el-table-column label="昵称" prop="nickname"></el-table-column>
                <el-table-column label="手机号" prop="phone" width="130"></el-table-column>
                <el-table-column label="最近一次的登录ip" prop="last_login_ip" width="150"></el-table-column>
                <el-table-column label="最近一次的登陆时间" prop="last_login_time"></el-table-column>
                <!-- <el-table-column label="状态" prop="mg_state">
                    作用域插槽:扩大子组件的作用范围
                    <template slot-scope="scope">
                        <el-switch :active-value="1" :inactive-value="0" v-model="scope.row.is_use"
                            @change="changeState(scope.row)">
                        </el-switch>
                    </template>
                </el-table-column> -->
                <!-- <el-table-column type="expand" label="角色的集合" width="100">
                    <template slot-scope="props">
                        <el-form label-position="left" inline class="demo-table-expand">
                            <el-form-item label="角色">
                                <el-checkbox-group v-model="props.row.admin_role_ids" @change="changeRole(props.row)">
                                    <el-checkbox v-for="item in userlists" :label="item.admin_role_id"
                                        :key="item.admin_role_id">{{ item.role_name }}</el-checkbox>
                                </el-checkbox-group>
                                <span>{{ props.row.roles_text }}:</span>
                            </el-form-item>
                        </el-form>
                    </template>
                </el-table-column> -->
                <el-table-column label="操作">
                    <template slot-scope="scope">
                        <el-button type="primary" icon="el-icon-edit" size="mini"
                            @click="showEditDialog(scope.row.admin_id)"></el-button>
                        <el-button type="danger" icon="el-icon-delete" size="mini"
                            @click=deleteEditDialog(scope.row)></el-button>
                    </template>
                </el-table-column>
            </el-table>
            <!--分页区域-->
            <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                :current-page="queryinfo.page" :page-sizes="[5, 10, 15, 20]" :page-size="queryinfo.list_rows"
                layout="total, sizes, prev, pager, next, jumper" :total="total">
            </el-pagination>
        </el-card>
        <!--新增文章-->
        <el-dialog title="新增管理员" :visible.sync="addDialogVisible" width="50%" @closed="addDialogClosed">
            <el-form :model="addForm" :rules="addRules" ref="addFormRef" label-width="100px">
                <el-form-item label="昵称" prop="nickname">
                    <el-input v-model="addForm.nickname"></el-input>
                </el-form-item>
                <el-form-item label="管理员账号" prop="account">
                    <el-input v-model="addForm.account"></el-input>
                </el-form-item>
                <el-form-item label="管理员密码 " prop="password">
                    <el-input v-model="addForm.password"></el-input>
                </el-form-item>
                <el-form-item label="手机号" prop="phone">
                    <el-input v-model="addForm.phone"></el-input>
                </el-form-item>
                <el-form-item label="角色">
                    <el-checkbox-group v-model="addForm.admin_role_ids">
                        <el-checkbox border v-for="(item, index) in userlists" :label="item.admin_role_id" :key="index">{{
                            item.role_name }}</el-checkbox>
                    </el-checkbox-group>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="addDialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="addUser">确 定</el-button>
            </span>
        </el-dialog>
        <!-- 修改管理员信息 -->
        <el-dialog title="修改文章信息" :visible.sync="editDialogVisible" width="50%" @closed="editDialogClosed">
            <el-form :model="editForm" :rules="editRules" ref="editFormRef" label-width="70px">
                <el-form-item label="昵称" prop="nickname">
                    <el-input v-model="editForm.nickname"></el-input>
                </el-form-item>
                <el-form-item label="手机号" prop="phone">
                    <el-input v-model="editForm.phone"></el-input>
                </el-form-item>
                <el-form-item label="密码" prop="password">
                    <el-input v-model="editForm.password"></el-input>
                </el-form-item>
                <el-form-item label="状态" prop="is_use">
                    <el-switch :active-value="1" :inactive-value="0" v-model="editForm.is_use">
                    </el-switch>
                </el-form-item>
                <el-form-item label="角色" prop="is_use">
                    <el-checkbox-group v-model="editForm.admin_role_ids">
                        <el-checkbox v-for="item in userlists" :label="item.admin_role_id" :key="item.admin_role_id">{{
                            item.role_name }}</el-checkbox>
                    </el-checkbox-group>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="editDialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="editUser">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
export default {
    data() {
        return {
            // 获取文章信息列表
            queryinfo: {
                query: '',
                page: 1,
                list_rows: 5,
            },
            checkboxGroup1: [],
            usestatuss: {},
            usestatustwo: {},
            userlist: [],
            userlists: [],
            usestatus: null,
            total: 0,
            //控制创建文章对话框展示与隐藏
            addDialogVisible: false,
            addForm: {
                nickname: '',
                account: '',
                password: '',
                phone: '',
                admin_role_ids: [],
            },
            addRules: {
                nickname: [{
                    required: true,
                    message: '请输入昵称',
                    trigger: 'blur'
                }],
                account: [{
                    required: true,
                    message: '请输入管理员账号',
                    trigger: 'blur'
                }],
                password: [{
                    required: true,
                    message: '请输入密码',
                    trigger: 'blur'
                }],

                phone: [{
                    required: true,
                    message: '请输入手机号',
                    trigger: 'blur'
                }],
            },
            editDialogVisible: false,
            editForm: {},
            editRules: {
                nickname: [{
                    required: true,
                    message: '请输入角色名称',
                    trigger: 'blur'
                }],
                phone: [{
                    required: true,
                    message: '请输入菜单id集合',
                    trigger: 'blur'
                }],
            },
            ids: {
                id: 0,
            },
            // 图片上传组件的headers请求头对象
            headerObj: {
                Authorization: window.sessionStorage.getItem('token')
            },
            previewPath: '',
            previewVisible: false
        }
    },
    created() {
        this.getinforList()
        this.getinforLists()
        console.log(this.$route)
    },
    methods: {
        // 获取所管理员
        async getinforList() {
            const { data: res } = await this.$http.get('admin/index', { params: this.queryinfo });

            if (res.code !== 0) return this.$message.err('获取拓展信息列表失败!')
            this.userlist = res.data.data;
            this.total = res.data.total;
            console.log(this.userlist);
        },
        // 获取所有菜单
        async getinforLists() {
            const { data: res } = await this.$http.get('adminRole/list');

            if (res.code !== 0) return this.$message.err('获取拓展信息列表失败!')
            console.log(res)
            this.userlists = res.data
            // this.total = res.data.total;
            console.log(res.data);
        },
        // 监听handleSizeChange方法
        handleSizeChange(newsize) {
            this.queryinfo.list_rows = newsize
            this.getinforList()
        },
        // 监听handleCurrentChange方法
        handleCurrentChange(newpage) {
            this.queryinfo.page = newpage
            this.getinforList()
        },
        // 添加用户关闭
        addDialogClosed() {
            this.$refs['addFormRef'].resetFields();
        },
        addUser() {
            // 预验证
            console.log(1245, this.addForm);
            this.$refs.addFormRef.validate(async valid => {
                if (!valid) return
                // 调用后台数据创建用户
                const { data: res } = await this.$http.post('admin/add', this.addForm)
                if (res.code !== 0) return this.$message.error(res.message)
                this.$message.success('添加成功')
                //    关闭添加窗口
                this.addDialogVisible = false
                this.getinforList()
            })
        },
        //删除用户
        async deleteEditDialog(row) {
            console.log(row)
            // console.log(id);
            const confirmRessult = await this.$confirm('此操作将永久删除, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).catch(err => err)

            if (confirmRessult !== 'confirm') {
                return this.$message.info('已经取消删除!')
            }
            const {
                data: res
            } = await this.$http.post(`admin/delete`, { admin_id: row.admin_id, admin_role_ids: row.admin_role_ids })
            console.log(res)
            if (res.code == 0) {
                this.$message.success('删除成功！')
                this.getinforList()
            } else {
                this.$message.error('删除失败！')
            }
            // if (res.meta.status !== 200) {
            //     return this.$message.error('删除失败!')
            // }
            // console.log(res);
            // this.$message.success('删除成功!')
            // this.getinforList()
        },
        // 展示要修改的内容
        async showEditDialog(id) {
            console.log(id);
            this.ids.admin_id = id
            this.editDialogVisible = true
            const { data: res } = await this.$http.put('admin/detail', this.ids)
            if (res.code !== 0) return this.$message.error('查询失败')
            this.editForm = res.data
            console.log(this.editForm)
        },
        editDialogClosed() {
            this.$refs['editFormRef'].resetFields();
        },
        editUser() {
            // 预验证
            this.$refs.editFormRef.validate(async valid => {
                if (!valid) return
                // 调用后台数据创建用户
                const { data: res } = await this.$http.post('admin/update', this.editForm)
                if (res.code !== 0) return this.$message.error(res.message)
                this.$message.success('编辑成功')
                //    关闭添加窗口
                this.editDialogVisible = false
                this.getinforList()

            })
        },
        // 改变用户状态
        async changeState(userinfo) {
            console.log(userinfo)
            this.usestatuss.id = userinfo.id
            this.usestatuss.use_status = userinfo.use_status
            const { data: res } = await this.$http.put("admin/modifyUseStatus", this.usestatuss)
            if (res.code !== 0) return this.$message.error(res.message)
            this.$message.success('改变用户状态成功')
        },
        //改变角色合集
        async changeRole(item) {
            console.log(item);
            this.usestatustwo.admin_id = item.admin_id
            this.usestatustwo.admin_role_ids = item.admin_role_ids
            const { data: res } = await this.$http.post("admin/updateRole", this.usestatustwo)
            if (res.code !== 0) return this.$message.error(res.message)
            this.$message.success('改变用户角色成功')
        }
    },
}
</script>

<style lang="less" scoped></style>