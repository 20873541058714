<template>
    <div>
        <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item to="/home">首页</el-breadcrumb-item>
            <el-breadcrumb-item>通告</el-breadcrumb-item>
        </el-breadcrumb>
        <el-card>
            <el-row :gutter="20">
                <el-col :span="5">
                    <el-input clearable placeholder=" 请输入内容" v-model="queryinfo.title">
                        <el-button slot="append" icon="el-icon-search" @click="reqNotification"></el-button>
                    </el-input>
                </el-col>
                <el-col :span="3.5">
                    <el-select v-model="queryinfo.notification_type" placeholder="请选择">
                        <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                        </el-option>
                    </el-select>
                </el-col>
                <el-col :span="4">
                    <el-button type="primary" @click="addNotification">添加通告</el-button>
                </el-col>
            </el-row>
            <el-table :data="userlist" border stripe>
                <el-table-column label="通告ID" prop="notification_id"></el-table-column>
                <el-table-column label="标题" prop="title"></el-table-column>
                <el-table-column label="内容" prop="content"></el-table-column>
                <el-table-column label="通告类型" prop="notification_type_text"></el-table-column>
                <el-table-column label="上架时间" prop="create_time"></el-table-column>
                <el-table-column label="操作" prop="create_time">
                    <template slot-scope="scope">
                        <el-button type="primary" icon="el-icon-edit" size="mini"
                            @click="showEditDialog(scope.row.user_id)"></el-button>
                        <el-button type="danger" icon="el-icon-delete" size="mini"
                            @click="deleteEditDialog(scope.row.user_id)"></el-button>
                    </template>
                </el-table-column>
            </el-table>
        </el-card>
        <el-dialog :title="addForm.notification_id ? '修改通告' : '添加通告'" :visible.sync="dialogFormVisible"
            width="750px">
            <el-form style="width: 80%;" label-position="right" label-width="120px">
                <el-form-item label="通告标题：">
                    <el-input placeholader="请输入通告标题" v-model="addForm.title"></el-input>
                </el-form-item>
                <el-form-item label="内容：">
                    <el-input placeholader="请输入内容" v-model="addForm.content"></el-input>
                </el-form-item>
                <el-form-item label="通告类型：">
                    <el-select v-model="addForm.notification_type" placeholder="请选择">
                        <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="dialogFormVisible = false">取 消</el-button>
                <el-button type="primary" @click="confirm">确 定</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
export default {
    data() {
        return {
            queryinfo: {
                title: "",
                page: 1,
                list_rows: 5,
                notification_type: 1
            },
            userlist: [],
            options: [
                {
                    value: 1,
                    label: '活动通知'
                },
                {
                    value: 2,
                    label: '官方通告'
                },
                {
                    value: 3,
                    label: '违规通告'
                }
            ],
            total: 0,
            addForm: {
                title: "",
                content: "",
                notification_type: ""
            },
            dialogFormVisible: false
        }
    },
    methods: {
        async reqNotification() {
            const { data: res } = await this.$http.post('notification/index', this.queryinfo)
            this.userlist = res.data.data
            this.total = res.data.total
        },
        addOrUpdateNotification(data) {
            if (data.notification_id) {
                return this.$http.post('notification/update', data)
            } else {
                return this.$http.post('notification/add', data)
            }
        },
        addNotification(){
            this.addForm.title = ""
            this.addForm.content = ""
            this.addForm.notification_type = ""
            this.addForm.notification_id = ""
            this.dialogFormVisible = true
        },
        async confirm() {
            const { data: res } = await this.addOrUpdateNotification(this.addForm)
            if (res.code == 0) {
                this.$message.success(this.addOrUpdateNotification.drawing_board_category_id ? "修改成功" : "添加成功")
            }else{
                this.$message.error(this.addOrUpdateNotification.drawing_board_category_id ? "修改失败" : "添加失败")
            }
            this.dialogFormVisible = false
            this.reqNotification()
        },
    },
    mounted() {
        this.reqNotification()
    }
}
</script>

<style lang="scss" scoped></style>