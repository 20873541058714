<template>
    <div>
        <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item>提现记录</el-breadcrumb-item>
        </el-breadcrumb>
        <el-card>
            <el-row :gutter="20">
                <el-col :span="5">
                    <el-select v-model="queryinfo.check_status" placeholder="请选择" @change="reqWithdrawCashLog">
                        <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                        </el-option>
                    </el-select>
                </el-col>
            </el-row>
            <el-table :data="recordList" border stripe>
                <el-table-column label="提现记录ID" prop="withdraw_cash_order_id"></el-table-column>
                <el-table-column label="用户名称" prop="applicant.nickname"></el-table-column>
                <el-table-column label="银行卡类型" prop="bank_type_text"></el-table-column>
                <el-table-column label="银行卡号" prop="bank_account"></el-table-column>
                <el-table-column label="提现图币" prop="currency"></el-table-column>
                <el-table-column label="提现时间" prop="allow_start_time"></el-table-column>
                <el-table-column label="提现状态" prop="check_status_text"></el-table-column>
                <el-table-column label="操作">
                    <template slot-scope="scope">
                        <el-button type="primary" size="mini" @click="viewRecords(scope.row)">查看</el-button>
                        <el-button type="primary" icon="el-icon-edit" size="mini"
                            @click="updateWithdrawCashLog(scope.row.withdraw_cash_log_id)"></el-button>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                :current-page="queryinfo.page" :page-sizes="[5, 10, 15, 20]" :page-size="queryinfo.list_rows"
                layout="total, sizes, prev, pager, next, jumper" :total="total">
            </el-pagination>
        </el-card>
        <el-dialog title="审核" :visible.sync="dialogFormVisible" width="500px">
            <el-form :model="updateForm" label-width="80px">
                <el-form-item label="审核状态">
                    <el-radio v-model="updateForm.value" label="1" border>通过</el-radio>
                    <el-radio v-model="updateForm.value" label="2" border>驳回</el-radio>
                </el-form-item>
                <el-form-item label="审核理由">
                    <el-input v-model="updateForm.check_reason"></el-input>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="editDialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="confirm">确 定</el-button>
            </span>
        </el-dialog>
        <el-dialog :title="'查看记录-'+ name" :visible.sync="dialogViewRecords" width="900px">
            <!-- <el-radio-group v-model="isCollapse" style="margin-bottom: 20px;">
                <el-radio-button :label="1">分享币记录</el-radio-button>
                <el-radio-button :label="2">廊币记录</el-radio-button>
            </el-radio-group>
            <div v-show="isCollapse == 1">
                <el-table :data="coinList" border stripe>
                    <el-table-column label="分享币ID" prop="coin_log_id"></el-table-column>
                    <el-table-column label="盈利类型" prop="profit_type_text"></el-table-column>
                    <el-table-column label="渠道类型" prop="channel_type_text"></el-table-column>
                    <el-table-column label="金额" prop="coin"></el-table-column>
                    <el-table-column label="时间" prop="create_time"></el-table-column>
                </el-table>
                <el-pagination @size-change="handleCoinChange" @current-change="handleCoinCurrentChange"
                    :current-page="coinForm.page" :page-sizes="[5, 10, 15, 20]" :page-size="coinForm.list_rows"
                    layout="total, sizes, prev, pager, next, jumper" :total="coinTotal">
                </el-pagination>
            </div> -->
            <div>
                <el-table :data="currencyList" border stripe>
                    <el-table-column label="ID" prop="currency_log_id"></el-table-column>
                    <el-table-column label="盈利类型" prop="profit_type_text"></el-table-column>
                    <el-table-column label="渠道类型" prop="channel_type_text"></el-table-column>
                    <el-table-column label="金额" prop="currency"></el-table-column>
                    <el-table-column label="时间" prop="create_time"></el-table-column>
                </el-table>
                <el-pagination @size-change="handleCurrencyChange" @current-change="handleCurrencyCurrentChange"
                    :current-page="currencyFrom.page" :page-sizes="[5, 10, 15, 20]" :page-size="currencyFrom.list_rows"
                    layout="total, sizes, prev, pager, next, jumper" :total="currencyTotal">
                </el-pagination>
            </div>
        </el-dialog>
    </div>
</template>

<script>
export default {
    data() {
        return {
            recordList: [],
            queryinfo: {
                check_status: '1',
                page: 1,
                list_rows: 5,
            },
            options: [
                {
                    value: '1',
                    label: '待审核'
                },
                {
                    value: '2',
                    label: '通过'
                },
                {
                    value: '3',
                    label: '驳回'
                }
            ],
            total: 0,
            updateForm: {
                value: "",
                check_reason: ""
            },
            dialogFormVisible: false,
            dialogViewRecords: false,
            coinForm: {
                page: 1,
                list_rows: 5
            },
            coinTotal:0,
            coinList: [],
            isCollapse: 1,
            currencyFrom: {
                page: 1,
                list_rows: 5
            },
            currencyList: [],
            currencyTotal:0,
            name:""
        }
    },
    methods: {
        async reqWithdrawCashLog() {
            const { data: res } = await this.$http.post('withdrawCashOrder/index', this.queryinfo)
            this.recordList = res.data.data
            this.total = res.data.total
            console.log(this.recordList)
        },
        handleSizeChange(newsize) {
            this.queryinfo.list_rows = newsize
            this.reqWithdrawCashLog()
        },
        handleCurrentChange(newpage) {
            this.queryinfo.page = newpage
            this.reqWithdrawCashLog()
        },
        updateWithdrawCashLog(id) {
            this.updateForm.withdraw_cash_log_id = id
            this.updateForm.value = "",
                this.updateForm.check_reason = ""
            this.dialogFormVisible = true
        },
        async confirm() {
            const { data: res } = await this.passThroughOrReject(this.updateForm)
            if (res.code == 0) {
                this.$message.success("审核完成")
                this.reqWithdrawCashLog()
                this.dialogFormVisible = false
            } else {
                this.dialogFormVisible = false
                this.$message.error("审核失败")
            }
        },
        passThroughOrReject(data) {
            if (data.value == '1') {
                return this.$http.post('withdrawCashOrder/pass', data)
            } else {
                return this.$http.post('withdrawCashOrder/reject', data)
            }
        },
        viewRecords(row) {
            // this.coinForm = {
            //     page: 1,
            //     list_rows: 5
            // }
            this.currencyFrom = {
                page: 1,
                list_rows: 5
            }
            this.currencyFrom.user_id = row.applicant.user_id
            this.name = row.applicant.nickname
            this.dialogViewRecords = true
            this.isCollapse = 1
            // this.reqCoinLogIndex()
            this.reqCurrencyLog()
        },
        async reqCoinLogIndex() {
            const { data: res } = await this.$http.post('coinLog/index', this.coinForm)
            this.coinTotal = res.data.total
            this.coinList = res.data.data
        },
        handleCoinChange(newsize) {
            this.coinForm.list_rows = newsize
            this.reqCoinLogIndex()
        },
        handleCoinCurrentChange(newpage) {
            this.coinForm.page = newpage
            this.reqCoinLogIndex()
        },
        async reqCurrencyLog() {
            const { data: res } = await this.$http.post('user.currencyLog/index',this.currencyFrom)
            this.currencyList = res.data.data
            this.currencyTotal = res.data.total
        },
        handleCurrencyChange(newsize) {
            this.currencyFrom.list_rows = newsize
            this.reqCurrencyLog()
        },
        handleCurrencyCurrentChange(newpage) {
            this.currencyFrom.page = newpage
            this.reqCurrencyLog()
        },
    },
    mounted() {
        this.reqWithdrawCashLog()
    }
}
</script>

<style lang="less" scoped>
.coin {
    border: 1px solid black;
    padding: 2px 8px;
}
</style>