<template>
    <div>
        <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item to="/home">首页</el-breadcrumb-item>
            <el-breadcrumb-item>欢迎词</el-breadcrumb-item>
        </el-breadcrumb>
        <el-card>
            <el-row :gutter="20">
                <el-col :span="4">
                    <el-button type="primary" @click="addWelcome">添加</el-button>
                </el-col>
            </el-row>
            <div class="tree-box">
                <Tree v-for="item in welcome" :welcomeData="item" :key="item.welcome_word_id"
                    @updateWelcome="updateWelcome" @deleteWelcome="deleteWelcome" @openTable="openTable"
                    @addTowWelcome="addTowWelcome" />
            </div>
            <!-- <el-table :data="welcome" border stripe>
                <el-table-column label="ID" prop="welcome_word_id"></el-table-column>
                <el-table-column label="分类名称" prop="category_name"></el-table-column>
                <el-table-column label="欢迎词前" prop="words_front_part"></el-table-column>
                <el-table-column label="欢迎词后" prop="words_rear_part"></el-table-column>
                <el-table-column label="排序" prop="sort"></el-table-column>
                <el-table-column label="上传时间" prop="create_time"></el-table-column>
                <el-table-column label="操作">
                    <template slot-scope="scope">
                        <el-button type="primary" icon="el-icon-edit" size="mini"
                            @click="updateWelcome(scope.row)"></el-button>
                        <el-button type="danger" icon="el-icon-delete" size="mini"
                            @click="deleteWelcome(scope.row.welcome_word_id)"></el-button>
                        <el-button type="danger" size="mini" @click="openTable(scope.row)">移出</el-button>
                    </template>
</el-table-column>
</el-table> -->
        </el-card>
        <el-dialog :title="this.addForm.welcome_word_id ? '修改欢迎词' : '添加欢迎词'" :visible.sync="dialogFormVisible"
            width="750px">
            <el-form style="width: 80%;" label-position="right" label-width="120px">
                <el-form-item label="分类名称：" prop="category_name">
                    <el-input placeholader="请输入职业名称" v-model="addForm.category_name"></el-input>
                </el-form-item>
                <el-form-item label="欢迎词前部分：" prop="words_front_part">
                    <el-input placeholader="请输入欢迎词前部分：" v-model="addForm.words_front_part"></el-input>
                </el-form-item>
                <el-form-item label="欢迎词后部分：" prop="words_rear_part">
                    <el-input placeholader="请输入欢迎词后部分" v-model="addForm.words_rear_part"></el-input>
                </el-form-item>
                <el-form-item label="图片预览" prop="image_url">
                    <el-upload border class="avatar-uploader" action="https://api.hxltw.cn/admin/upload/image"
                        :auto-upload="false" :headers="headerObj" :show-file-list="false" :on-change="handleChange">
                        <img v-if="addForm.image_url" :src="addForm.image_url" class="avatar">
                        <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                    </el-upload>
                </el-form-item>

                <el-form-item label="图片地址：" prop="jump_url">
                    <el-input placeholader="请输入排序" v-model="addForm.jump_url"></el-input>
                </el-form-item>
                <el-form-item label="排序：" prop="sort">
                    <el-input placeholader="请输入排序" v-model="addForm.sort"></el-input>
                </el-form-item>
            </el-form>
            <template #footer>
                <el-button type="primary" size="default" @click="cancel">取消</el-button>
                <el-button type="primary" size="default" @click="confirm">确定</el-button>
            </template>
        </el-dialog>
        <el-dialog title="修改二级欢迎词" :visible.sync="visible" width="750px">
            <el-form style="width: 80%;" label-position="right" label-width="120px">
                <el-form-item label="画廊名称：" prop="drawing_board_name">
                    <el-input placeholader="请输入职业名称" v-model="addForm2.drawing_board_name"></el-input>
                </el-form-item>
                <el-form-item label="图片预览：" prop="image_url">
                    <el-upload border class="avatar-uploader" action="https://api.hxltw.cn/admin/upload/image"
                        :auto-upload="false" :headers="headerObj" :show-file-list="false" :on-change="handleChange2">
                        <img v-if="addForm2.image_url" :src="addForm2.image_url" class="avatar">
                        <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                    </el-upload>
                </el-form-item>
                <el-form-item label="图片地址：" prop="jump_url">
                    <el-input placeholader="图片地址" v-model="addForm2.jump_url"></el-input>
                </el-form-item>
                <el-form-item label="排序：" prop="sort">
                    <el-input placeholader="请输入排序" v-model="addForm2.sort"></el-input>
                </el-form-item>
            </el-form>
            <template #footer>
                <el-button type="primary" size="default" @click="cancel2">取消</el-button>
                <el-button type="primary" size="default" @click="confirm2">确定</el-button>
            </template>
        </el-dialog>
    </div>
</template>

<script>
import Tree from "./components/tree.vue";
export default {
    components: {
        Tree
    },
    provide() {
        return {
            openTable: this.openTable,
            deleteAdDrawingBoard:this.deleteAdDrawingBoard
        }
    },
    data() {
        return {
            welcome: [],
            defaultProps: {
                children: 'ad_drawing_boards',
                label: 'welcome_word_id'
            },
            addForm: {
                category_name: '',
                words_front_part: '',
                words_rear_part: '',
                sort: '',
                image_url: '',
                jump_url: ''
            },
            dialogFormVisible: false,
            visible: false,
            welcomeWord: [],
            id: null,
            uploadUrl: 'http://127.0.0.1:8887/api/upload',
            headerObj: {
                Authorization: window.sessionStorage.getItem('token')
            },
            addForm2: {
                drawing_board_name: '',
                image_url: '',
                jump_url: '',
                sort: '',
            }
        }
    },
    methods: {
        async reqWelcome() {
            const { data: res } = await this.$http.post('welcomeWord/all')
            this.welcome = res.data
            console.log(res)
        },
        addOrUpdateWlcome(data) {
            if (data.welcome_word_id) {
                return this.$http.post('welcomeWord/update', data)
            } else {
                return this.$http.post('welcomeWord/add', data)
            }
        },
        addWelcome() {
            this.addForm.category_name = ''
            this.addForm.words_front_part = ''
            this.addForm.words_rear_part = ''
            this.addForm.sort = ''
            this.addForm.welcome_word_id = ''
            this.addForm.image_url = ''
            this.addForm.jump_url = ''
            this.dialogFormVisible = true
        },
        async confirm() {
            const { data: res } = await this.addOrUpdateWlcome(this.addForm)
            if (res.code == 0) {
                this.$message.success(this.addForm.welcome_word_id ? '修改成功' : '添加成功')
                this.reqWelcome()
            } else {
                this.$message.error(this.addForm.welcome_word_id ? '修改失败' : '添加失败')
            }
            this.dialogFormVisible = false
        },
        async deleteWelcome(id) {
            const confirmResult = await this.$confirm(
                "此操作将永久删除该分类, 是否继续?",
                "提示",
                {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning",
                }
            ).catch((err) => err);
            if (confirmResult !== "confirm")
                return this.$message.info("已经取消删除！");
            const { data: res } = await this.$http.post('welcomeWord/delete', { welcome_word_id: id })
            if (res.code == 0) {
                this.$message.success('删除成功')
                this.reqWelcome()
            } else {
                this.$message.error('删除失败')
            }
        },
        updateWelcome(row) {
            Object.assign(this.addForm, row)
            this.dialogFormVisible = true
        },
        cancel() {
            this.dialogFormVisible = false
        },
        openTable(row) {
            Object.assign(this.addForm2, row)
            this.visible = true
        },
        async reqWelcomeWord() {
            const { data: res } = await this.$http.post('welcome_word.drawing/list', { welcome_word_id: this.id })
            this.welcomeWord = res.data
        },
        async remove(row) {
            console.log(row)
            await this.$http.post('welcome_word.drawing/remove', { welcome_word_id: this.id, drawing_id: row.drawing_id })
            this.reqWelcomeWord()
        },
        handleChange(file) {
            console.log(file)
            if (file.raw.type == "image/png" || file.raw.type == "image/jpeg" || file.raw.type == "image/webp") {
                this.doUploadImg(file)
            } else {
                this.$message.error("请上传图片格式")
                this.fileList = []
            }
            // const {data:res} = await this.$http.post("upload/image")
            // console.log(res)
        },
        async doUploadImg(file) {
            let formData = new FormData()
            formData.append('image', file.raw)
            const { data: res } = await this.$http.post("upload/image", formData)
            this.addForm.image_url = res.data.url
            this.addForm.image_file_id = res.data.file_id
        },
        cancel2() {
            this.visible = false
        },
        async confirm2() {
            // console.log(this.addForm2.ad_drawing_board_id)

            //  this.addOrUpdateAdDrawingBoard()
            const { data: res } = await this.addOrUpdateAdDrawingBoard(this.addForm2)
            if (res.code == 0) {
                this.$message.success(this.addForm2.ad_drawing_board_id ? '修改成功' : '添加成功')
                this.reqWelcome()
            } else {
                this.$message.error(this.addForm2.ad_drawing_board_id ? '修改失败' : '添加失败')
            }
            this.visible = false
        },
        handleChange2(file) {
            console.log(file)
            if (file.raw.type == "image/png" || file.raw.type == "image/jpeg" || file.raw.type == "image/webp") {
                this.doUploadImg2(file)
            } else {
                this.$message.error("请上传图片格式")
                this.fileList = []
            }
            // const {data:res} = await this.$http.post("upload/image")
            // console.log(res)
        },
        addOrUpdateAdDrawingBoard() {
            if (!this.addForm2.ad_drawing_board_id) {
                return this.$http.post('welcome_word.adDrawingBoard/add', this.addForm2)
            } else {
                console.log(this.addForm2.ad_drawing_board_id)
                return this.$http.post('welcome_word.adDrawingBoard/update', this.addForm2)
            }
        },
        addTowWelcome(id) {
            this.addForm2.ad_drawing_board_id = ''
            this.addForm2.drawing_board_name = ''
            this.addForm2.image_url = ''
            this.addForm2.jump_url = ''
            this.addForm2.sort = ''
            this.addForm2.welcome_word_id = id
            this.visible = true
        },
        async doUploadImg2(file) {
            let formData = new FormData()
            formData.append('image', file.raw)
            const { data: res } = await this.$http.post("upload/image", formData)
            this.addForm2.image_url = res.data.url
            this.addForm2.image_file_id = res.data.file_id
        },
        async deleteAdDrawingBoard(id) {
            const confirmResult = await this.$confirm(
                "此操作将永久删除该分类, 是否继续?",
                "提示",
                {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning",
                }
            ).catch((err) => err);
            if (confirmResult !== "confirm")
                return this.$message.info("已经取消删除！");
            const { data: res } = await this.$http.post('welcome_word.adDrawingBoard/delete', { ad_drawing_board_id: id })
            if (res.code == 0) {
                this.$message.success('删除成功')
                this.reqWelcome()
            } else {
                this.$message.error('删除失败')
            }
        },
    },
    mounted() {
        this.reqWelcome()
    }
}
</script>

<style scoped>
::v-deep .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
}

::v-deep .el-upload:hover {
    border-color: #409EFF;
}

.avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
}

.avatar {
    width: 178px;
    height: 178px;
    display: block;
}

::v-deep .el-tree-node__content {
    padding: 15px 0;
}

.tree-box {
    margin-top: 16px;
}
</style>