<template>
    <div>
        <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item>菜单管理</el-breadcrumb-item>
            <el-breadcrumb-item>菜单列表</el-breadcrumb-item>
        </el-breadcrumb>
        <el-card>
            <el-row :gutter="20">

                <el-col :span="4">
                    <el-button type="primary" @click="addDialogVisible = true">添加一级菜单</el-button>
                </el-col>
            </el-row>
            <el-tree :data="userlist" :props="defaultProps" @node-click="handleNodeClick" node-key="id"
                :expand-on-click-node="false" style="margin-top: 25px;">
                <span class="custom-tree-node" slot-scope="{ node, data }">
                    <span style="width: 100px; display: inline-block;">{{ data.admin_menu_id }}</span>
                    <span style="width: 150px; display: inline-block;">{{ node.label }}</span>
                    <span style="width: 200px; display: inline-block;">{{ data.html_path }}</span>
                    <span style="width: 100px; display: inline-block;">
                        <span style="width: 100px; display: inline-block;">
                            <el-tag v-if="node.level === 1">一级</el-tag>
                            <el-tag type="success" v-else-if="node.level === 2">二级</el-tag>
                            <el-tag type="warning" v-else>三级</el-tag>
                        </span>
                        <el-button type="primary" icon="el-icon-edit" size="mini"
                            @click="showEditDialog(data.admin_menu_id)" style="width: 100px; display: inline-block;">
                            编辑
                        </el-button>

                        <el-button type="primary" icon="el-icon-edit" v-if="node.level === 1" size="mini"
                            @click="showEditDialogs(data.admin_menu_id)">添加二级菜单</el-button>
                        <el-button type="danger" icon="el-icon-delete" size="mini" style="margin-left: 50px;"
                            @click=deleteEditDialog(data.admin_menu_id)>
                            删除
                        </el-button>
                    </span>
                </span>
            </el-tree>
        </el-card>


        <!--创建一级菜单-->
        <el-dialog title="创建一级菜单" :visible.sync="addDialogVisible" width="30%" @closed="addDialogClosed">
            <el-form :model="addForm" :rules="addRules" ref="addFormRef" label-width="90px">
                <el-form-item label="菜单名" prop="menu_name">
                    <el-input v-model="addForm.menu_name"></el-input>
                </el-form-item>
                <el-form-item label="网页名称" prop="html_name">
                    <el-input v-model="addForm.html_name"></el-input>
                </el-form-item>
                <el-form-item label="路径" prop="html_path">
                    <el-input v-model="addForm.html_path"></el-input>
                </el-form-item>
                <el-form-item label="接口路径" prop="api_path">
                    <el-input v-model="addForm.api_path"></el-input>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="addDialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="addUser">确 定</el-button>
            </span>
        </el-dialog>
        <!--创建二级菜单-->
        <el-dialog title="创建二级菜单" :visible.sync="addDialogVisibles" width="30%" @closed="addDialogClosed">
            <el-form :model="addForm" :rules="addRules" ref="addFormRef" label-width="90px">
                <el-form-item label="菜单名" prop="menu_name">
                    <el-input v-model="addForm.menu_name"></el-input>
                </el-form-item>
                <el-form-item label="网页名称" prop="html_name">
                    <el-input v-model="addForm.html_name"></el-input>
                </el-form-item>
                <el-form-item label="路径" prop="html_path">
                    <el-input v-model="addForm.html_path"></el-input>
                </el-form-item>
                <el-form-item label="接口路径" prop="api_path">
                    <el-input v-model="addForm.api_path"></el-input>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="addDialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="addUser">确 定</el-button>
            </span>
        </el-dialog>
        <!-- 编辑用户 -->
        <el-dialog title="编辑菜单" :visible.sync="editDialogVisible" width="30%" @closed="editDialogClosed">
            <el-form :model="editForm" :rules="editRules" ref="editFormRef" label-width="90px">
                <el-form-item label="菜单名" prop="menu_name">
                    <el-input v-model="editForm.menu_name"></el-input>
                </el-form-item>
                <el-form-item label="网页名称" prop="html_name">
                    <el-input v-model="editForm.html_name"></el-input>
                </el-form-item>
                <el-form-item label="路径" prop="html_path">
                    <el-input v-model="editForm.html_path"></el-input>
                </el-form-item>
                <el-form-item label="接口路径" prop="api_path">
                    <el-input v-model="editForm.api_path"></el-input>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="editDialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="editUser">确 定</el-button>
            </span>
        </el-dialog>
        <!-- 用户权限分配 -->
        <el-dialog title="用户权限分配" :visible.sync="authDialogVisible" width="50%">
            <div class="authbox">
                <p><span>当前用户:{{ authForm.mg_name }}</span></p>
                <p><span>当前权限:{{ authForm.role_name }}</span></p>
                <p>
                    <el-select v-model="role_id" placeholder="请选择">
                        <el-option v-for="item in RoleList" :key="item.role_id" :label="item.role_name"
                            :value="item.role_id">
                        </el-option>
                    </el-select>
                </p>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="authDialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="authUser">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
export default {
    data() {
        // var checkEmail=(rule,value,cb)=>{
        //     // 正则表达式
        //     var regEmail=/^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+(\.([a-zA-Z0-9_-]))+/
        //     if(regEmail.test(value)) return cb()
        //     cb(new Error('请输入合法的邮箱'))
        // }
        // var checkmobile=(rule,value,cb)=>{
        //     // 正则表达式
        //      const regMobile = /^(0|86|17951)?(13[0-9]|15[012356789]|17[678]|18[0-9]|14[57])[0-9]{8}$/
        //     if(regMobile.test(value)) return cb()
        //     cb(new Error('请输入合法的手机号码'))
        // }
        return {
            // 获取用户列表
            queryinfo: {
                query: '',
                pagenum: 1,
                pagesize: 2,
            },
            userlist: [],
            total: 0,
            //控制创建用户对话框展示与隐藏
            addDialogVisible: false,
            addForm: {
                parent_id: 0,
                menu_name: '',
                html_name: '',
                html_path: "",
                api_path: "",
                sort: 1
            },
            // 控制编辑用户对话框展示与隐藏
            editDialogVisible: false,
            // 权限分配
            authDialogVisible: false,
            authForm: {},
            RoleList: [],
            role_id: '',
            addDialogVisibles: false,
            updataForm: {
                id: '',
                role_id: '',
            },
            maun: {
                admin_menu_id: ''
            },
            editForm: {
                sort: 1,
            },
            addRules: {
                menu_name: [
                    { required: true, message: '请输入菜单名称', trigger: 'blur' },
                    // { min: 2, max: 10, message: '长度在 2 到 10 个字符', trigger: 'blur' }
                ],
                html_name: [
                    { required: true, message: '请输入网页名称', trigger: 'blur' },
                    // { min: 2, max: 20, message: '长度在 2 到 20 个字符', trigger: 'blur' }
                ],
                html_path: [
                    { required: true, message: '请输入跳转路径', trigger: 'blur' },
                ],
                api_path:[
                { required: true, message: '请输入接口路径', trigger: 'blur' },
                ]
            },
            editRules: {
                menu_name: [
                    { required: true, message: '请输入菜单名称', trigger: 'blur' },
                    // { min: 2, max: 10, message: '长度在 2 到 10 个字符', trigger: 'blur' }
                ],
                html_name: [
                    { required: true, message: '请输入网页名称', trigger: 'blur' },
                    // { min: 2, max: 20, message: '长度在 2 到 20 个字符', trigger: 'blur' }
                ],
                html_path: [
                    { required: true, message: '请输入跳转路径', trigger: 'blur' },
                ],
                api_path:[
                { required: true, message: '请输入接口路径', trigger: 'blur' },
                ]
            },
            defaultProps: {
                children: 'children',
                label: 'menu_name'
            }
        }
    },
    created() {
        this.getUserList()
    },
    methods: {
        //查询用户列表
        async getUserList() {
            const { data: res } = await this.$http.post('adminMenu/family');

            if (res.code !== 0) return this.$message.err('获取用户列表失败!')
            console.log(res.data);
            this.userlist = res.data;
            this.total = res.data.total;
        },
        // 添加用户关闭
        addDialogClosed() {
            this.$refs['addFormRef'].resetFields();
        },

        //删除用户
        async deleteEditDialog(ids) {
            console.log(ids);
            this.maun.admin_menu_id = ids
            const confirmRessult = await this.$confirm('此操作将永久删除, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).catch(err => err)

            if (confirmRessult !== 'confirm') {
                return this.$message.info('已经取消删除!')
            }
            const { data: res } = await this.$http.post('adminMenu/delete', this.maun)
            if (res.code !== 0) {
                return this.$message.error('删除失败!')
            }
            console.log(res);
            this.$message.success('删除成功!')
            this.getUserList()
        },
        // 展示要修改用户
        async showEditDialog(ids) {
            console.log(ids);
            this.maun.admin_menu_id = ids
            this.editDialogVisible = true
            const { data: res } = await this.$http.post('adminMenu/detail', this.maun)
            if (res.code !== 0) return this.$message.error('查询失败')
            this.editForm = res.data
            console.log(this.editForm)
            // this.addForm.parent_id = this.editForm.id
            // this.addForm.sort = 7
        },
        // 展示要添加的二级领域
        async showEditDialogs(ids) {
            console.log(ids);
            this.maun.admin_menu_id = ids
            this.addDialogVisibles = true
            // const { data: res } = await this.$http.post('adminMenu/detail', this.maun)
            // if (res.code !== 0) return this.$message.error('查询失败')
            // this.editForm = res.data
            this.addForm.parent_id = ids
            // this.addForm.parent_id = this.editForm.id
            // this.addForm.sort = 7

            console.log(this.addForm.parent_id);
        },
        addUser() {
            console.log(this.addForm)
            // 预验证
            this.$refs.addFormRef.validate(async valid => {
                if (!valid) return
                // 调用后台数据创建用户
                const { data: res } = await this.$http.post('adminMenu/add', this.addForm)
                if (res.code !== 0) return this.$message.error(res.message)
                this.$message.success('添加用户成功')
                //    关闭添加窗口
                this.addForm.parent_id = 0
                this.addDialogVisible = false
                this.addDialogVisibles = false
                this.getUserList()
                
            })
        },
        editDialogClosed() {
            this.$refs['editFormRef'].resetFields();
        },
        editUser() {

            // 预验证
            this.$refs.editFormRef.validate(async valid => {
                if (!valid) return
                // 调用后台数据创建用户
                const { data: res } = await this.$http.post('adminMenu/update', this.editForm)
                if (res.code !== 0) return this.$message.error(res.message)
                this.$message.success('编辑用户成功')
                //    关闭添加窗口
                this.editDialogVisible = false
                this.getUserList()

            })
        },
        async authUser() {
            this.updataForm.role_id = this.role_id
            // console.log(this.role_id);
            const { data: ress } = await this.$http.post('adminMenu/delete', this.updataForm)
            if (ress.code !== 0) return this.$message.error(ress.message)
            this.$message.success(ress.message)
            // 关闭对话框
            this.authDialogVisible = false
            // 刷新页面
            this.getUserList()

        },
        handleNodeClick() {

        },

    },
}

</script>

<style lang="less" scoped>
::v-deep .el-tree-node__content {
    padding: 15px 0;
}
</style>