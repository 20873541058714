<template>
    <div>
        <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item to="/home">首页</el-breadcrumb-item>
            <el-breadcrumb-item>素材订单</el-breadcrumb-item>
        </el-breadcrumb>
        <el-card>
            <el-row :gutter="20">
                <el-col :span="7">
                    <el-select v-model="queryinfo.order_type" placeholder="请选择" @change="reqMaterialOrder">
                        <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                        </el-option>
                    </el-select>
                </el-col>
            </el-row>
            <el-table :data="userlist" border stripe>
                <el-table-column label="素材订单ID" prop="material_order_id"></el-table-column>
                <el-table-column label="订单类型" prop="order_type_text"></el-table-column>
                <el-table-column label="销售廊币" prop="sale_currency"></el-table-column>
                <el-table-column label="上架时间" prop="create_time"></el-table-column>
                <el-table-column label="作者" prop="author.nickname"></el-table-column>
            </el-table>
            <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                :current-page="queryinfo.page" :page-sizes="[5, 10, 15, 20]" :page-size="queryinfo.list_rows"
                layout="total, sizes, prev, pager, next, jumper" :total="total">
            </el-pagination>
        </el-card>
    </div>
</template>

<script>
export default {
    data() {
        return {
            queryinfo: {
                order_type: "1",
                page: 1,
                list_rows: 5,
            },
            options: [
                {
                    value: '1',
                    label: '普通下载'
                },
                {
                    value: '2',
                    label: '会员下载'
                }
            ],
            userlist: [],
            total: 0
        }
    },
    methods: {
        async reqMaterialOrder() {
            const { data: res } = await this.$http.post('materialOrder/index', this.queryinfo)
            console.log(res)
            this.userlist = res.data.data
            this.total = res.data.total
        },
        handleSizeChange(newsize) {
            this.queryinfo.list_rows = newsize;
            this.reqMaterialOrder();
        },
        // 监听handleCurrentChange方法
        handleCurrentChange(newpage) {
            this.queryinfo.page = newpage;
            this.reqMaterialOrder();
        },
    },
    mounted() {
        this.reqMaterialOrder()
    }
}
</script>

<style lang="scss" scoped></style>