<template>
    <div>
        <div class="Ttree-item">
            <div style="width: 100px;">
                <span>{{ ad_drawing_boards.ad_drawing_board_id }}</span>
            </div>
            <div style="width: 120px;">
                <span>{{ ad_drawing_boards.drawing_board_name }}</span>
            </div>
            <div style="width: 230px;">
                <div class="cls_url" @click="jumpUrl(ad_drawing_boards.jump_url)">{{ ad_drawing_boards.jump_url }}</div>
            </div>

            <div style="width: 60px; display: inline-block;">
                <el-tag type="success">二级</el-tag>
            </div>
            <el-button type="primary" icon="el-icon-edit" size="mini" @click="openTable(ad_drawing_boards)">
                编辑
            </el-button>
            <el-button type="danger" icon="el-icon-delete" size="mini" style="margin-left: 50px;"
                @click="deleteAdDrawingBoard(ad_drawing_boards.ad_drawing_board_id)">
                删除
            </el-button>
        </div>
    </div>

</template>

<script>
export default {
    props: ['ad_drawing_boards'],
    inject: ['openTable', 'deleteAdDrawingBoard'],
    methods: {
        jumpUrl(url) {
            if (url.slice(0, 4) == 'http') {
                window.location.href = url
            } else {
                window.location.href = 'http://' + url
            }

        }
    }
}
</script>

<style scoped>
.Ttree-item {
    padding: 15px 56px;
    cursor: pointer;
    display: flex;
    align-items: center;
}

.Ttree-item>div {
    display: flex;
    align-items: center;
    margin-right: 45px;
}

.Ttree-item:hover {
    background-color: #eaedf1;
}

.cls_url {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
</style>