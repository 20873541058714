<template>
    <div>
        <div class="tree-item" @click="isExpand = !isExpand" :class="isExpand && 'bg'">
            <div style="width: 100px;">
                <img src="@/assets/img/廊图网25.png" alt="" :class="isExpand && 'zk'"
                    v-if="welcomeData.ad_drawing_boards">{{ welcomeData.welcome_word_id }}
            </div>
            <div style="width: 120px;">
                <span>{{ welcomeData.category_name }}</span>
            </div>
            <div style="width: 150px;">
                <span>{{ welcomeData.words_front_part }}</span>
            </div>
            <div style="width: 220px;">
                <span>{{ welcomeData.words_rear_part }}</span>
            </div>
            <div style="width: 100px; display: inline-block;">
                <el-tag>一级</el-tag>
            </div>
            <el-button type="primary" icon="el-icon-edit" size="mini" @click.stop="open">
                编辑
            </el-button>

            <el-button type="primary" size="mini" @click.stop="addTowWelcome">添加二级菜单</el-button>
            <el-button type="danger" icon="el-icon-delete" size="mini" style="margin-left: 50px;"
                @click.stop="deleteWelcome">
                删除
            </el-button>
        </div>
        <div v-show="welcomeData.ad_drawing_boards && isExpand">
            <Ttree :ad_drawing_boards="item" v-for="item in welcomeData.ad_drawing_boards" :key="item.ad_drawing_board_id"></Ttree>
        </div>

    </div>

</template>

<script>
import Ttree from './twotree.vue'
export default {
    components: { Ttree },
    props: ['welcomeData'],
    data() {
        return {
            isExpand: false
        }
    },
    methods: {
        open() {
            this.$emit('updateWelcome', this.welcomeData)
        },
        deleteWelcome() {
            this.$emit('deleteWelcome', this.welcomeData.welcome_word_id)
        },
        addTowWelcome(){
            this.$emit('addTowWelcome', this.welcomeData.welcome_word_id)
        }
    }
}
</script>

<style scoped>
.tree-item {
    padding: 15px;
    cursor: pointer;
    display: flex;
    align-items: center;
}

.tree-item>div {
    display: flex;
    align-items: center;
    margin-right: 45px;
}

.tree-item:hover {
    background-color: #eaedf1;
}

.tree-item img {
    height: 22px;
    margin-right: 5px;
    transition: all 0.2s ease-in-out;

}

.zk {
    transform: rotate(90deg);
}

.bg {
    background-color: #eaedf1;
}
</style>./ttree.vue