import Vue from 'vue'
import App from './App.vue'
import router from './router'
import './assets/css/golbal.css'
import './assets/fonts/iconfont.css'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';

import TreeTable from 'vue-table-with-tree-grid';
// 导入副文本编辑器
import VueQuillEditor from 'vue-quill-editor'
// 导入对应样式
import 'quill/dist/quill.core.css' // import styles
import 'quill/dist/quill.snow.css' // for snow theme
import 'quill/dist/quill.bubble.css' // for bubble theme

//导入 Nprogress 包对应的js和css
import Nprogress from 'nprogress'
import 'nprogress/nprogress.css'


// 配置axios
import axios from 'axios'
axios.defaults.baseURL = 'https://api.hxltw.cn/admin/'

// 在request拦截器中，展示进度条 Nprogress。start()
axios.interceptors.request.use(config => {
    Nprogress.start()
        // console.log(config);
    config.headers.Authorization = window.sessionStorage.getItem('token')
    return config
})

// 在request拦截器中，隐藏进度条Nprogress.done()
axios.interceptors.response.use(config => {
    Nprogress.done()
    return config
})
Vue.prototype.$http = axios

Vue.use(ElementUI);
Vue.config.productionTip = false

Vue.component('tree-table', TreeTable)
    // 将副文本编辑器注册成全局可用的组件
Vue.use(VueQuillEditor)

Vue.filter('dateFormat', function(originVal) {

    const dt = new Date(originVal)
    console.log(6789, dt);
    console.log(67892345, originVal);

    const y = dt.getFullYear()
    const m = (dt.getMonth() + 1 + '').padStart(2, '0')
    const d = (dt.getDate() + '').padStart(2, '0')

    const hh = (dt.getHours() + '').padStart(2, '0')
    const mm = (dt.getMinutes() + '').padStart(2, '0')
    const ss = (dt.getSeconds() + '').padStart(2, '0')

    return `${y}-${m}-${d} ${hh}:${mm}:${ss}`


});

new Vue({
    router,
    render: h => h(App)
}).$mount('#app')