<template>
    <div>
        <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item>热门问题</el-breadcrumb-item>
        </el-breadcrumb>
        <el-card>
            <el-row :gutter="20">
                <el-col :span="5">
                    <el-input clearable placeholder="请输入内容" v-model="queryinfo.title">
                        <el-button slot="append" icon="el-icon-search" @click="reqHotQuestion"></el-button>
                    </el-input>
                </el-col>
                <el-col :span="4">
                    <el-button type="primary" @click="addHotTopics">添加热门问题</el-button>
                </el-col>
            </el-row>
            <el-table :data="hotQuestion" border stripe>
                <el-table-column label="热门问题ID" prop="hot_question_id"></el-table-column>
                <el-table-column label="标题" prop="title"></el-table-column>
                <el-table-column label="内容" prop="content"></el-table-column>
                <el-table-column label="上架时间" prop="create_time"></el-table-column>
                <el-table-column label="操作">
                    <template slot-scope="scope">
                        <el-button type="primary" icon="el-icon-edit" size="mini" @click="updateHotTopics(scope.row)"></el-button>
                        <el-button type="danger" icon="el-icon-delete" size="mini"
                            @click="deletehotTopics(scope.row.hot_question_id)"></el-button>
                    </template>
                </el-table-column>
            </el-table>
        </el-card>
        <el-dialog :title="this.addForm.hot_question_id ? '修改热门问题' : '添加热门问题'" :visible.sync="dialogFormVisible" width="650px">
            <el-form v-model="addForm" label-width="100px" style="width: 80%;">
                <el-form-item label="标题：" prop="title">
                    <el-input v-model="addForm.title" />
                </el-form-item>
                <el-form-item label="内容：" prop="content">
                    <el-input v-model="addForm.content" />
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="cancel">取 消</el-button>
                <el-button type="primary" @click="confirm">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
export default {
    data() {
        return {
            queryinfo: {
                title: '',
                page: 1,
                list_rows: 5,
            },
            hotQuestion: [],
            addForm: {
                title: "",
                content: ""
            },
            dialogFormVisible: false
        }
    },
    methods: {
        async reqHotQuestion() {
            const { data: res } = await this.$http.post('hotQuestion/index', this.queryinfo)
            this.hotQuestion = res.data.data
        },
        addOrUpdateHotTopics(data) {
            if (data.hot_question_id) {
                return this.$http.post('hotQuestion/update', data)
            } else {
                return this.$http.post('hotQuestion/add', data)
            }
        },
        addHotTopics() {
            this.addForm.title = ""
            this.addForm.content = ""
            this.addForm.hot_question_id = ""
            this.dialogFormVisible = true
        },
        async confirm() {
            const { data: res } = await this.addOrUpdateHotTopics(this.addForm)
            if (res.code == 0) {
                this.$message.success(this.addForm.hot_question_id ? '修改成功' : '添加成功')
                this.reqHotQuestion()
            } else {
                this.$message.error(this.addForm.hot_question_id ? '修改失败' : '添加失败')
            }
            this.dialogFormVisible = false
        },
        async deletehotTopics(id) {
            const confirmResult = await this.$confirm(
                "此操作将永久删除该分类, 是否继续?",
                "提示",
                {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning",
                }
            ).catch((err) => err);
            if (confirmResult !== "confirm") return this.$message.info("已经取消删除！");
            const {data:res} = await this.$http.post('hotQuestion/delete',{hot_question_id:id})
            if(res.code == 0){
                this.$message.success("删除成功")
                this.reqHotQuestion()
            }else{
                this.$message.error("删除失败")
            }
        },
        updateHotTopics(row){
            Object.assign(this.addForm,row)
            this.dialogFormVisible = true
        },
        cancel(){
            this.dialogFormVisible = false
        }
    },
    mounted() {
        this.reqHotQuestion()
    }
}
</script>

<style lang="scss" scoped></style>