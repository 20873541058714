<template>
    <div>
        <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item>词典管理</el-breadcrumb-item>
            <el-breadcrumb-item>热搜词</el-breadcrumb-item>
        </el-breadcrumb>
        <el-card>
            <el-row :gutter="20">
                <el-col :span="5">
                    <el-input clearable placeholder="请输入内容" v-model="queryinfo.word">
                        <el-button slot="append" icon="el-icon-search"></el-button>
                    </el-input>
                </el-col>
                <el-col :span="4">
                    <el-button type="primary" @click="addHotSearchWord">添加热搜词</el-button>
                </el-col>
            </el-row>
            <el-table :data="userlist" border stripe>
                <el-table-column label="热搜词ID" prop="hot_search_word_id"></el-table-column>
                <el-table-column label="词语" prop="word"></el-table-column>
                <el-table-column label="排序" prop="sort"></el-table-column>
                <el-table-column label="上传时间" prop="create_time"></el-table-column>
                <el-table-column label="操作">
                    <template slot-scope="scope">
                        <el-button type="primary" icon="el-icon-edit" size="mini"
                            @click="updateHotSearchWord(scope.row)"></el-button>
                        <el-button type="danger" icon="el-icon-delete" size="mini"
                            @click="deleteHotSearchWord(scope.row.hot_search_word_id)"></el-button>
                    </template>
                </el-table-column>
            </el-table>
        </el-card>
        <el-dialog :title="addForm.hot_search_word_id ? '修改热搜词' : '添加热搜词'" :visible.sync="dialogFormVisible" width="650px">
            <el-form v-model="addForm" label-width="100px" style="width: 80%;">
                <el-form-item label="热搜词汇：" prop="word">
                    <el-input v-model="addForm.word" />
                </el-form-item>
                <el-form-item label="排序：" prop="sort">
                    <el-input v-model="addForm.sort" />
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="cancel">取 消</el-button>
                <el-button type="primary" @click="confirm">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
export default {
    data() {
        return {
            queryinfo: {
                word: '',
                page: 1,
                list_rows: 5,
            },
            total: 0,
            userlist: [],
            addForm: {
                word: "",
                sort: ""
            },
            dialogFormVisible: false
        }
    },
    methods: {
        async reqHotSearchWord() {
            const { data: res } = await this.$http.post('hotSearchWord/index')
            console.log(res)
            this.userlist = res.data.data
            this.total = res.data.total
        },
        addOrUpdateHotSearchWord(data) {
            if (data.hot_search_word_id) {
                //修改热搜词
                return this.$http.post('hotSearchWord/update', data)
            } else {
                //添加热搜词
                return this.$http.post('hotSearchWord/add', data)
            }
        },
        addHotSearchWord() {
            this.addForm.word = ""
            this.addForm.sort = ""
            this.addForm.hot_search_word_id = ""
            this.dialogFormVisible = true
        },
        cancel() {
            this.dialogFormVisible = false
        },
        async confirm() {
            const { data: res } = await this.addOrUpdateHotSearchWord(this.addForm)
            if (res.code == 0) {
                this.$message.success(this.addForm.hot_search_word_id ? '修改成功' : '添加成功')
                this.reqHotSearchWord()
            } else {
                this.$message.error(this.addForm.hot_search_word_id ? '修改失败' : '添加失败')
            }
            this.dialogFormVisible = false
        },
        updateHotSearchWord(row) {
            console.log(row)
            Object.assign(this.addForm, row)
            this.dialogFormVisible = true
        },
        async deleteHotSearchWord(id) {
            console.log(id)
            const confirmResult = await this.$confirm(
                "此操作将永久删除该分类, 是否继续?",
                "提示",
                {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning",
                }
            ).catch((err) => err);
            // console.log(row);
            if (confirmResult !== "confirm")
                return this.$message.info("已经取消删除！");
            const { data: res } = await this.$http.post('hotSearchWord/delete', { hot_search_word_id: id })
            if (res.code == 0) {
                this.$message.success("删除成功")
                this.reqHotSearchWord()
            } else {
                this.$message.error('删除失败')
            }
        }
    },
    mounted() {
        this.reqHotSearchWord()
    }
}
</script>

<style lang="scss" scoped></style>