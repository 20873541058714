<template>
    <div>
        <template v-for="item in menuList">
            <template v-if="!item.children">
                <el-menu-item :key="item.html_path" :index="item.html_path">
                    <i :class="item.api_path"></i>
                    <template slot="title">
                        <span>{{ item.menu_name }}</span>
                    </template>
                </el-menu-item>
            </template>
            <template v-else>
                <el-submenu :index="item.html_path" :key="item.html_path">

                    <template #title>
                        <i :class="item.api_path"></i>

                        <span>{{ item.menu_name }}</span>
                    </template>
                    <el-menu-item v-for="data in item.children" :key="data.html_path" :index="data.html_path">
                        <template #title>
                            <span>{{ data.menu_name }}</span>
                        </template>
                    </el-menu-item>
                </el-submenu>
            </template>
        </template>
    </div>
</template>

<script>
export default {
    props: ['menuList'],
    mounted() {
        console.log(this.menuList)
    },
    name: 'Menu'
}
</script>

<style lang="scss" scoped></style>