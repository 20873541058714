<template>
    <div>
        <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item>用户管理</el-breadcrumb-item>
            <el-breadcrumb-item>设计师认证</el-breadcrumb-item>
        </el-breadcrumb>
        <el-card>
            <el-row :gutter="20">
                <el-col :span="5">
                    <el-select v-model="queryinfo.check_status" placeholder="请选择" @change="reqDesignerList">
                        <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                        </el-option>
                    </el-select>
                </el-col>
            </el-row>

            <el-table :data="designerList" border stripe>

                <el-table-column label="设计师ID" prop="designer_id"></el-table-column>
                <el-table-column label="以往作品链接" prop="previous_work_url"></el-table-column>
                <el-table-column label="申请理由" prop="apply_reason"></el-table-column>
                <el-table-column label="审核状态" prop="check_status_text"></el-table-column>
                <el-table-column label="申请时间" prop="create_time"></el-table-column>
                <!-- <el-table-column label="使用状态">
                    <template slot-scope="scope">
                        <el-switch :active-value="1" :inactive-value="0" v-model="scope.row.use_status" active-text="是否开启"
                            @change="modifyUseStatus(scope.row.id, scope.row.use_status)">
                        </el-switch>
                    </template>
                </el-table-column> -->
                <el-table-column label="操作">
                    <template slot-scope="scope">
                        <el-button type="primary" icon="el-icon-edit" size="mini"
                            @click="showEditDialog(scope.row.designer_id)"></el-button>

                    </template>
                </el-table-column>
            </el-table>
            <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                :current-page="queryinfo.page" :page-sizes="[5, 10, 15, 20]" :page-size="queryinfo.list_rows"
                layout="total, sizes, prev, pager, next, jumper" :total="total">
            </el-pagination>
        </el-card>
        <el-dialog title="审核" :visible.sync="dialogFormVisible" width="500px">
            <el-form :model="updateForm" label-width="80px">
                <el-form-item label="审核状态">
                    <el-radio v-model="updateForm.value" label="1" border>通过</el-radio>
                    <el-radio v-model="updateForm.value" label="2" border>驳回</el-radio>
                </el-form-item>
                <el-form-item label="审核理由">
                    <el-input v-model="updateForm.check_reason"></el-input>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="editDialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="submitReview">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
export default {
    data() {
        return {
            options: [
                {
                    value: '1',
                    label: '待审核'
                }, {
                    value: '2',
                    label: '通过'
                }, {
                    value: '3',
                    label: '驳回'
                }
            ],
            queryinfo: {
                check_status: '1',
                page: 1,
                list_rows: 5,
            },
            designerList: [],
            total: 0,
            dialogFormVisible: false,
            updateForm: {
                designer_id: "",
                check_reason: "",
                value: "1"
            },

        }
    },
    methods: {
        async reqDesignerList() {
            const { data: res } = await this.$http.post('designer/index', this.queryinfo)
            this.designerList = res.data.data
            this.total = res.data.total;
        },
        handleSizeChange(newsize) {
            this.queryinfo.list_rows = newsize
            this.reqDesignerList()
        },
        handleCurrentChange(newpage) {
            this.queryinfo.page = newpage
            this.reqDesignerList()
        },
        showEditDialog(id) {
            this.dialogFormVisible = true
            this.updateForm.designer_id = id
        },
        async submitReview() {
            if (this.updateForm.value == 1) {
                const { data: res } = this.$http.post('designer/checkPass', this.updateForm)
                if (res.code == 0) {
                    this.dialogFormVisible = false
                    this.$message.success("审核完成")
                    this.reqDesignerList()

                } else {
                    this.dialogFormVisible = false
                    this.$message.error("审核失败")
                }
            } else {
                const { data: res } = this.$http.post('designer/checkRejected', this.updateForm)
                if (res.code == 0) {
                    this.dialogFormVisible = false
                    this.$message.success("审核完成")
                    this.reqDesignerList()

                } else {
                    this.dialogFormVisible = false
                    this.$message.error("审核失败")
                }
            }
        }
    },
    mounted() {
        this.reqDesignerList()
    }
}
</script>

<style lang="scss" scoped></style>