<template>
    <div>
        <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item>行业咨询</el-breadcrumb-item>
        </el-breadcrumb>
        <el-card>
            <el-row :gutter="20">
                <el-col :span="5">
                    <el-input clearable placeholder="请输入内容" v-model="queryinfo.title">
                        <el-button slot="append" icon="el-icon-search"></el-button>
                    </el-input>
                </el-col>
                <el-col :span="3.5">
                    <el-select v-model="queryinfo.industry_news_type" placeholder="请选择" @change="reqIndustryNews">
                        <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                        </el-option>
                    </el-select>
                </el-col>
                <el-col :span="4">
                    <el-button type="primary" @click="addIndustryNews">添加行业咨询</el-button>
                </el-col>
            </el-row>
            <el-table :data="industryNews" border stripe>
                <el-table-column label="行业资讯ID" prop="industry_news_id"></el-table-column>
                <el-table-column label="标题" prop="title"></el-table-column>
                <el-table-column label="图片预览">
                    <template slot-scope="scope">
                        <el-image style="width: 60px; height: 60px" :src="scope.row.image_url"
                            :preview-src-list="[scope.row.image_url]">
                        </el-image>
                    </template>
                </el-table-column>
                <el-table-column label="内容">
                    <template slot-scope="scope">
                        <div class="content">{{ scope.row.content }}</div>
                    </template>
                </el-table-column>
                <el-table-column label="上传时间" prop="create_time"></el-table-column>
                <el-table-column label="操作">
                    <template slot-scope="scope">
                        <el-button type="primary" icon="el-icon-edit" size="mini"
                            @click="updateIndustryNews(scope.row)"></el-button>
                        <el-button type="danger" icon="el-icon-delete" size="mini"
                            @click=deleteEditDialog(scope.row.industry_news_id)></el-button>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                :current-page="queryinfo.page" :page-sizes="[5, 10, 15, 20]" :page-size="queryinfo.list_rows"
                layout="total, sizes, prev, pager, next, jumper" :total="total">
            </el-pagination>
        </el-card>
        <el-dialog title="添加链接分类" :visible.sync="dialogFormVisible" width="80%" :close-on-click-modal="false">
            <el-form v-model="addForm" label-width="100px" style="width: 80%;">
                <el-form-item label="标题：" prop="title">
                    <el-input v-model="addForm.title" />
                </el-form-item>
                <el-form-item label="排序：" prop="sort">
                    <el-input v-model="addForm.sort" />
                </el-form-item>
                <el-form-item label="简介：" prop="brief_introduction">
                    <el-input v-model="addForm.brief_introduction" />
                </el-form-item>
                <el-form-item label="是否推荐：" prop="is_recommend">
                    <el-select v-model="addForm.is_recommend" placeholder="请选择">
                        <el-option v-for="item in options2" :key="item.value" :label="item.label" :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>

                <el-form-item label="分类：" prop="industry_news_type">
                    <el-select v-model="addForm.industry_news_type" placeholder="请选择">
                        <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="图片预览：">
                    <el-upload border class="avatar-uploader" action="https://api.hxltw.cn/admin/upload/image"
                        :auto-upload="false" :headers="headerObj" :show-file-list="false" :on-change="handleChange">
                        <img v-if="addForm.image_url" :src="addForm.image_url" class="avatar">
                        <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                    </el-upload>
                </el-form-item>
                <div>
                    <toolbar :editor="editor" style="
                           border-bottom: 1px solid #dcdfe6;
                           width: 100%;
                           border-radius: 4px;" :default-config="toolbarConfig" mode="default" />
                    <Editor v-model="addForm.content" style="height: 300px; overflow-y: hidden"
                        :default-config="editorConfig" mode="default" @onCreated="onCreated" />
                </div>

            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="cancel">取 消</el-button>
                <el-button type="primary" @click="confirm">确 定</el-button>
            </span>
        </el-dialog>

    </div>
</template>

<script>
import { Editor, Toolbar } from "@wangeditor/editor-for-vue";
export default {
    components: { Editor, Toolbar },
    data() {
        return {
            queryinfo: {
                title: "",
                page: 1,
                list_rows: 5,
                industry_news_type: 1
            },
            options2: [
                {
                    value: 0,
                    label: '否'
                },
                {
                    value: 1,
                    label: '是'
                }
            ],
            toolbarConfig: {},
            editorConfig: {
                placeholder: '请输入文本内容...',
                readOnly: false, // 只读
                MENU_CONF: {
                    // 配置上传图片
                    uploadImage: {
                        server: 'https://api.hxltw.cn/admin/upload/image', // 配置图片上传地址
                        maxFileSize: 2 * 1024 * 1024, // 10M  图片大小限制
                        fieldName: 'image', // 上传名字
                        allowedFileTypes: [], // 选择文件时的类型限制，默认为 ['image/*'] 。如不想限制，则设置为 []
                        // Headers: "Bearer " + getToken(),
                        // 自定义上传参数，传递图片时需要带一些参数过去写在这。参数会被添加到 formData 中，一起上传到服务端。
                        // meta: {
                        //   image_class_id: "2",
                        //   file_type: "1",
                        // },
                        // 自定义设置请求头，比如添加token之类的
                        headers: {
                            Authorization: window.sessionStorage.getItem('token'),
                        },
                        // 上传进度的回调函数，可以用来显示进度条
                        onProgress(progress) {
                            // progress 是 0-100 的数字
                            console.log('progress', progress)
                        },
                        // // 单个文件上传成功之后
                        // onSuccess(file, res) {
                        //     console.log(`${file.name} 上传成功`, res)
                        // },

                        // 单个文件上传失败
                        onFailed(file, res) {
                            console.log(`${file.name} 上传失败`, res)
                        },

                        // 上传错误，或者触发 timeout 超时
                        onError(file, err, res) {
                            console.log(`${file.name} 上传出错`, err, res)
                        },

                        // 插入图片到富文本编辑器回显
                        customInsert(res, insertFn) {
                            console.log(res)
                            // res 即服务端的返回结果
                            // getPhotoUrl(res.data[0]).then((res) => {
                            //   const url = res.data
                            //   const alt = ''
                            //   const href = res.data
                            //   // 从 res 中找到 url alt href ，然后插入图片
                            insertFn(res.data.url, '')
                            // })
                        },
                    },
                    // 配置上传视频
                    uploadVideo: {
                        server: '#', // 配置视频上传地址
                        maxFileSize: 5 * 1024 * 1024, // 5M  视频大小限制
                        fieldName: 'multipartFile', // 上传名字
                        // 最多可上传几个文件，默认为 5
                        // maxNumberOfFiles: 1,
                        allowedFileTypes: [], // 选择文件时的类型限制，默认为 ['video/*'] 。如不想限制，则设置为 []
                        // 自定义上传参数，传递图片时需要带一些参数过去写在这。参数会被添加到 formData 中，一起上传到服务端。
                        // meta: {
                        //   type: 1,
                        // },
                        // 自定义设置请求头，比如添加token之类的
                        // headers: {
                        //     Accept: 'text/x-json',
                        //     otherKey: 'xxx'
                        // },
                        // metaWithUrl: false, // 将 meta 拼接到 url 参数中，默认 false
                        // withCredentials: true, // 跨域是否传递 cookie ，默认为 false
                        // 上传之前触发
                        onBeforeUpload(file) {
                            // file 选中的文件，格式如 { key: file }
                            return file
                            // 可以 return
                            // 1. return file 或者 new 一个 file ，接下来将上传
                            // 2. return false ，不上传这个 file
                        },

                        // 上传进度的回调函数，可以用来显示进度条
                        onProgress(progress) {
                            // progress 是 0-100 的数字
                            console.log('progress', progress)
                        },

                        // // 单个文件上传成功之后
                        onSuccess(file, res) {
                            console.log(`${file.name} 上传成功`, res)
                        },

                        // 单个文件上传失败
                        onFailed(file, res) {
                            console.log(`${file.name} 上传失败`, res)
                        },

                        // 上传错误，或者触发 timeout 超时
                        onError(file, err, res) {
                            console.log(`${file.name} 上传出错`, err, res)
                        },
                    },
                },
            },
            industryNews: [],
            total: 0,
            dialogFormVisible: false,
            addForm: {
                title: '',
                image_url: '',
                image_file_id: '',
                content: '',
                brief_introduction: '',
                industry_news_type: 1,
                is_recommend: 0,
                sort: ''
            },
            headerObj: {
                Authorization: window.sessionStorage.getItem('token')
            },
            options: [{
                value: 1,
                label: '行业人物专访'
            }, {
                value: 2,
                label: '行业论坛咨询'
            }],
            editor: null
        }
    },
    methods: {
        async reqIndustryNews() {
            const { data: res } = await this.$http.post('industryNews/index', this.queryinfo)
            this.industryNews = res.data.data
            this.total = res.data.total
        },
        handleSizeChange(newsize) {
            this.queryinfo.list_rows = newsize
            this.reqIndustryNews()
        },
        handleCurrentChange(newpage) {
            this.queryinfo.page = newpage
            this.reqIndustryNews()
        },
        handleChange(file) {
            console.log(file)
            if (file.raw.type == "image/png" || file.raw.type == "image/jpeg" || file.raw.type == "image/webp") {
                this.doUploadImg(file)
            } else {
                this.$message.error("请上传图片格式")
            }
            // const {data:res} = await this.$http.post("upload/image")
            // console.log(res)
        },
        async doUploadImg(file) {
            let formData = new FormData()
            formData.append('image', file.raw)
            const { data: res } = await this.$http.post("upload/image", formData)
            this.addForm.image_url = res.data.url
            this.addForm.image_file_id = res.data.file_id
        },
        addIndustryNews() {
            this.addForm.title = ''
            this.addForm.image_url = ''
            this.addForm.image_file_id = ''
            this.addForm.content = ''
            this.addForm.industry_news_id = ''
            this.addForm.brief_introduction = ''
            this.addForm.industry_news_type = 1
            this.addForm.sort = ''
            this.addForm.is_recommend = 0
            this.dialogFormVisible = true
        },
        addOrUpdateIndustryNews(data) {
            if (data.industry_news_id) {
                return this.$http.post('industryNews/update', data)
            } else {
                return this.$http.post('industryNews/add', data)
            }
        },
        async confirm() {
            const { data: res } = await this.addOrUpdateIndustryNews(this.addForm)
            console.log(res)
            if (res.code == 0) {
                this.$message.success("添加成功")
            } else {
                this.$message.error("添加失败")
            }
            this.reqIndustryNews()
            this.dialogFormVisible = false
        },
        cancel() {
            this.dialogFormVisible = false
        },
        async deleteEditDialog(id) {
            const confirmResult = await this.$confirm(
                "此操作将永久删除该分类, 是否继续?",
                "提示",
                {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning",
                }
            ).catch((err) => err);
            // console.log(row);
            if (confirmResult !== "confirm")
                return this.$message.info("已经取消删除！");
            const { data: res } = await this.$http.post('industryNews/delete', { industry_news_id: id })
            if (res.code == 0) {
                this.$message.success('删除成功')
                this.reqIndustryNews()
            } else {
                this.$message.error('删除失败')
            }
        },
        updateIndustryNews(row) {
            console.log(row)
            Object.assign(this.addForm, row)
            this.dialogFormVisible = true
        },
        onCreated(editor) {
            this.editor = Object.seal(editor); // 一定要用 Object.seal() ，否则会报错

        },
    },
    mounted() {
        this.reqIndustryNews()
        console.log(window.sessionStorage.getItem('token'))
    },
    beforeDestroy() {
        const editor = this.editor;
        if (editor == null) return;
        editor.destroy(); // 组件销毁时，及时销毁编辑器
    },
}
</script>
<style src="@wangeditor/editor/dist/css/style.css"></style>
<style scoped>
.content {
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-word;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

::v-deep .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
}

::v-deep .el-upload:hover {
    border-color: #409EFF;
}

.avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
}

.avatar {
    width: 178px;
    height: 178px;
    display: block;
}
</style>